@use '../variables' as *;

footer {
    background-color: $white;
    box-shadow: 0 rem(-8px) rem(12px) rem(-6px) rgba(0, 0, 0, 0.15);
    margin-top: rem(15px);
    padding-top: rem(10px);
    z-index: 9;

    .row {
        margin-top: rem(10px);
    }

    .disclaimer {
        text-align: right;
    }

    p {
        color: $black;

        &.copyright,
        &.last-update {
            font-weight: 500;
            padding-top: rem(10px);
            text-align: right;
        }

        &.last-update {
            font-size: 0.9em;
        }
    }

    .follow-us {
        margin-bottom: rem(10px);
    }

    .footer-links {
        padding-left: rem(9px);
        padding-top: rem(10px);

        p {
            display: flex;
            flex-wrap: wrap;
            line-height: 1.5;

            a {
                font-weight: 600;
                padding: 0 rem(6px);
                position: relative;

                &::after {
                    background-color: $black;
                    content: '';
                    height: rem(16px);
                    position: absolute;
                    right: rem(-1px);
                    top: 50%;
                    transform: translateY(-50%);
                    width: rem(2px);
                }

                &:last-child {
                    &::after {
                        display: none;
                    }
                }
            }
        }
    }

    .border-top {
        position: relative;

        &::after {
            background-color: $black;
            content: '';
            height: rem(1px);
            left: rem(15px);
            position: absolute;
            top: 0;
            width: calc(100% - rem(30px));
        }
    }
}

@media screen and (max-width: 992px) {
    footer {
        .disclaimer {
            text-align: left;
        }

        p {
            &.copyright,
            &.last-update {
                text-align: left;
            }
        }
    }

}
