@use '../variables' as *;

.content-faq {
    h2 {
        border-bottom: rem(1px) solid $black;
        margin-bottom: rem(30px);
        padding-bottom: rem(10px);
    }

    .photo-story {
        &.row {
            padding-bottom: 0;
        }

        button.accordion-toggle {
            background: none;
            border: none;
            font-size: rem(18px);
            font-weight: 600;
            padding: rem(10px) rem(40px) 0 0;
            position: relative;
            text-align: left;
            width: 100%;

            &::after {
                font-size: rem(18px);
                position: absolute;
                right: 0;
                top: rem(20px);
            }
        }

        .col-md-12 {
            padding: 0;
        }
    }
}
