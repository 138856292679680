@use '../variables' as *;

.image-component {
    padding: rem(20px) 0 0 !important;

    img {
        display: block;
        height: auto;
        width: 100%;
    }
}
