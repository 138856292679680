$base-font-size: 16px;

// VARIABLES
$break-sm: 640px;
$break-md: 768px;
$break-lg: 1024px;
$break-xl: 1160px;
$break-2xl: 1600px;

// xl 1.25rem   -> font-size: 1.28rem; = 23pt
// lg 1.125rem -> font-size: 1.167 = 21pt
// base/md 1rem = 18pt
// sm+ 0. -> font-size: 0.rem = 17pt
// sm 0.875 -> font-size: 0.888rem = 16pt

// COLOURS

$black: #000000;
$white: #ffffff;
$grey: #808080;
$light-grey: #f0f0f0;
$light-grey-200: #dddddd;
$dark-grey: #606060;
$red: #ff3300;
$dark-red: #e02d00; // Used for WCAG (color contrast)
$shadow: #0000000f; // 0px 10px 20px hsl(0deg 0% 0% / 6%) // box shadow
$border: #d2d3d5;
$orange: #ff3300;
$blue: #66afe9;
$pink: #d90373;
$purple: #541471;
$blue: #0093dd;
$green: #3e9e1f;

// Social Media
$facebook: #3b5998;
$twitter: #00aced;
$linkedin: #007bb6;
$youtube: #b31217;

$font-stack-default: 'Ubuntu', sans-serif;


/* Colors Theme */
$themes: (
  'orange': $red,
  'pink': $pink,
  'purple': $purple,
  'blue': $blue,
  'green': $green
);


/* Filters */
$orange-filter: invert(31%) sepia(46%) saturate(6732%) hue-rotate(360deg) brightness(98%) contrast(106%);
$pink-filter: invert(19%) sepia(94%) saturate(4638%) hue-rotate(317deg) brightness(82%) contrast(109%);
$purple-filter: invert(8%) sepia(56%) saturate(6425%) hue-rotate(280deg) brightness(99%) contrast(91%);
$blue-filter: invert(35%) sepia(63%) saturate(2467%) hue-rotate(177deg) brightness(100%) contrast(101%);
$green-filter: invert(40%) sepia(97%) saturate(391%) hue-rotate(60deg) brightness(105%) contrast(94%);

$color-filters: (
  'orange': $orange-filter,
  'pink': $pink-filter,
  'purple': $purple-filter,
  'blue': $blue-filter,
  'green': $green-filter
);

// END FONTS

// Z-INDEX list
$zindex-navbar: 1000 !default;


// Convert px to rem
@function rem($size) {
  @return calc($size / $base-font-size * 1rem);
}

// Gradients colors
@mixin linear-gradient($color1, $color2, $color3) {
  background-color: $color3;
  background-image: linear-gradient(90deg, $color1 0%, $color2 50%, $color3 100%);
}

// Fading Entrance
@mixin fading-entrance($opacity, $transition, $delay, $transform) {
  opacity: $opacity;
  transform: translate3d(0, $transform, 0);
  transition: all $transition ease-in-out;
  transition-delay: $delay;
}

/* Generic ul */
@mixin generic-ul() {
  ul:not(.list-nav) {
    list-style: none;
    margin: rem(10px) 0;
    padding-left: rem(20px);

    li {
      position: relative;
      padding-left: rem(15px);
    }

    & > li::before {
      position: absolute;
      top: 0;
      left: 0;
      color: $black;
      content: '\2022';
      font-weight: bold;
      width: rem(15px);
    }
  }
}

/* END CSS */
