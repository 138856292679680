@use '../variables' as *;

 .carousel {
    .carousel-caption {
        bottom: inherit;
        color: $black;
        max-width: 50%;
        padding: rem(5px);
        text-shadow: none;
        z-index: 1;

        h1 {
            font-size: calc(16px + (22 - 16) * (100vw - 768px) / (1280 - 768));
        }

        h2 {
            font-size: calc(32px + (55 - 32) * (100vw - 768px) / (1280 - 768));
            font-weight: 300;
        }

        h2.h2-small {
            font-size: calc(26px + (45 - 26) * (100vw - 768px) / (1280 - 768));
        }

        div.author {
            font-size: calc(22px + (30 - 22) * (100vw - 768px) / (1280 - 768));
            line-height: 1.5;
        }

        div.author .text-small {
            font-size: 0.75em;
        }

        div {
            & > ul > li > a {
                color: $black;
                font-size: rem(16px);
                font-weight: bold;
            }
        }

        .white-box {
            background-color: rgba(255, 255, 255, 0.8);
            margin: rem(-15px);
            padding: rem(15px);
        }

        //<div style="font-size:24px;font-weight:300;line-height::1.6;">
        &.bottom-left {
            bottom: 10%;
            left: 3%;
            right: auto;
            text-align: left;
        }

        &.top-left {
            left: 3%;
            right: auto;
            text-align: left;
            top: 10%;
        }

        &.top-right {
            left: auto;
            right: 5%;
            text-align: left;
            top: 10%;
        }

        &.bottom-right {
            bottom: 10%;
            left: auto;
            right: 5%;
            text-align: left;
        }

        &-mobile {
            padding: 0 rem(10px);
        }
    }

    .carousel-item {
        backface-visibility: hidden;
        display: none;
        float: left;
        margin-right: -100%;
        position: relative;
        transition: transform 0.6s ease-in-out;
        width: 100%;

        &.active,
        &-next,
        &-prev {
            display: block;
        }

        .img-responsive {
            width: 100%;
        }
    }

    .page-heading {
        .color-override {
            p,
            ul > li > a {
                color: #1e1e1e;
            }
        }
    }

    .carousel-control {
        background-image: none;
        bottom: 0;
        color: $red;
        height: rem(55px);
        opacity: 1;
        top: auto;
        width: 6%;

        &:hover {
            opacity: 0.7;
        }

        &.left span,
        &.right span {
            bottom: rem(15px);
            top: auto;
        }
    }

    .carousel-indicators {
        li {
            border-color: $red;
            box-shadow: 0 rem(1px) rem(2px) rgba(0, 0, 0, 0.6);
            height: rem(16px);
            margin: 0;
            opacity: 1;
            width: rem(16px);

            &:hover {
                opacity: 0.7;
            }
        }

        .active {
            background-color: $red;
            height: rem(16px);
            width: rem(16px);
        }
    }

    .carousel-inner {
        overflow: hidden;
        position: relative;
        width: 100%;

      .carousel-item {
        background-repeat: no-repeat;
        background-size: cover;

        .hero-wrapper {
          padding: 0;
          position: relative;
        }
      }

      & > .carousel-item > div > img {
        display: block;
        height: auto;
        max-width: 100%;
      }
    }

    .image-section {
      height: rem(480px);
    }
}


 @media screen and (min-width: 768px) {
   .hero-carousel .carousel-item {
     height: 37.5vw;
     max-height: 480px;
     background-size: auto 100% !important;
   }

   .hero-carousel .image-section {
       height: 37.5vw;
       max-height: 480px;
   }
 }

/* Slider only component */
.content-slider {
    margin-bottom: rem(60px);

    .slider-component {
        min-height: rem(360px);

        .carousel-inner {
            display: flex;

            .carousel-item {
                display: block;
                opacity: 0;
                visibility: hidden;

                &.active {
                    opacity: 1;
                    visibility: visible;
                }

                img {
                    width: 100%;
                }
            }
        }

        .action-wrapper {
            left: 50%;
            margin-top: rem(20px);
            position: absolute;
            text-align: center;
            transform: translateX(-50%);
            width: auto;

            .carousel-indicators {
                display: flex;
                margin: 0;
                position: static;
                width: 100%;

                li {
                    margin-right: rem(5px);

                    &:last-child {
                        margin: 0
                    }
                }
            }

            .carousel-control {
                align-items: center;
                bottom: 0;
                display: flex;
                height: 100%;
                top: rem(1px);
                width: auto;

                &.left {
                    left: rem(-30px);
                }

                &.right {
                    right: rem(-30px);
                }
            }
        }
    }
}

.carousel-item-next:not(.carousel-item-left),
.active.carousel-item-right {
  transform: translateX(100%);
}

.carousel-item-prev:not(.carousel-item-right),
.active.carousel-item-left {
  transform: translateX(-100%);
}

/* ==========================================================================
+   Responsive
+   ========================================================================== */

@media only screen and (min-width: 1280px) {
  .carousel {
    .carousel-caption {
      h1 {
        font-size: 22px;
      }
      h2 {
        font-size: 55px;
      }
      h2.h2-small {
        font-size: 45px;
      }
      div.author {
        font-size: 30px;
      }
    }
  }
}
@media only screen and (max-width: 768px) {
  .carousel {
    .carousel-caption {
      h1 {
        font-size: 16px;
      }
      h2 {
        font-size: 32px;
      }
      h2.h2-small {
        font-size: 26px;
      }
      div.author {
        font-size: 24px;
      }
    }
  }
}


@media only screen and (max-width: 1280px) {
    .carousel {
        .carousel-caption {
            h1 {
                margin: 0 0 rem(5px);
            }

            h2 {
                margin: 0 0 rem(5px);
            }
        }
    }
}

@media only screen and (max-width: 992px) {
    /* Slider only component */
    .content-slider {
        .carousel.slider-component {
            min-height: auto;

            .action-wrapper {
                .carousel-indicators {
                    bottom: initial;
                    left: 0;
                    margin: 0;
                    position: relative;
                    top: unset;
                    width: 100%;
                }

                .carousel-control {
                    align-items: center;
                    bottom: 0;
                    display: flex;
                    height: 100%;
                }
            }
        }
    }
}

@media only screen and (max-width: 767px) {
    .carousel {
        .hero-fake-image {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 295px;
            position: absolute;
            left: 0;
            top: 0;
            z-index: 1;
            width: 100%;

            .hero-fake-indicators-wrapper {
                position: relative;
                width: 100%;
                height: 100%;

                .carousel-indicators {
                    margin-top: 0;
                    position: absolute;
                    bottom: 0;
                    display: block;
                    top: unset;
               }
            }
        }

        .carousel-caption {
            left: auto;
            max-width: none;
            position: relative;
            right: auto;

            .page-heading {
                margin: 0;
                padding: 0;
            }

            h1 {
                margin: rem(5px) 0;
            }

            h2 {
                margin-top: rem(10px);
            }

            p {
                color: $black !important;
            }


            .white-box {
                background-color: transparent;
                margin: 0;
                padding: 0;
            }

            div > ul > li > a {
                color: #1e1e1e;
                font-size: rem(16px);
                font-weight: bold;
            }

            &.bottom-left,
            &.bottom-right,
            &.middle-left,
            &.middle-right,
            &.top-left,
            &.top-right {
                bottom: auto;
                height: inherit;
                left: auto;
                padding: 0 rem(15px);
                right: auto;
                text-align: left;
                top: initial;
            }

            &.top-left,
            &.top-right {
                bottom: inherit;
            }
        }

        .carousel-indicators {
            margin-top: rem(-31px);
            position: relative;
            top: rem(230px);
        }

        .carousel-inner {
            .carousel-item {
                background-image: none !important;

                .hero-wrapper {
                    .image-section {
                        height: rem(275px);

                        &.hero-img-section {
                          height: auto;
                          position: relative;
                          width: 100%;

                          img {
                            width: 100%;
                            height: 100%;
                          }
                        }

                        &.hero-img-section.use-crop {
                            padding-top: 75%;
                            overflow: hidden;

                            img {
                                position: absolute;
                                top: 0;
                                left: 0;
                                object-fit: cover;
                            }
                        }
                    }
                }
            }
        }

        & + .hero-border-container {
            padding: 0;
        }
    }
}

@media screen and (min-width: 768px) {
    .carousel {
        .carousel-item {
            &.position-left {
                background-position: left;
            }

            &.position-center {
                background-position: center;
            }

            &.position-right {
                background-position: right;
            }
        }

        .carousel-indicators {
            bottom: 0;
        }
    }
}
