@use '../variables' as *;

.text-image {
    @include fading-entrance(0, 1s, unset, rem(20px));
    padding: rem(40px) 0;
    position: relative;
    width: 100%;

    .container {
        align-items: center;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        max-width: rem(1280px);
        padding: 0;
        width: 100%;

        .text {
            margin: rem(10px) 0;
            text-align: center;
            z-index: 2;
        }

        .img {
            @include fading-entrance(0, 0.5s, 0.5s, unset);
            margin: rem(10px);
            max-width: rem(220px);
            position: relative;
            width: 100%;
            z-index: 2;
        }
    }

    &.img-left {
        .container {
            .text {
                order: 2;
            }

            .img {
                order: 1;
            }
        }
    }

    &.active {
        opacity: 1;
        transform: translate3d(0, 0, 0);

        .img {
            opacity: 1;
        }
    }
}

/* ==========================================================================
   Responsive
   ========================================================================== */

    @media screen and (min-width: 600px) {
        .text-image {
            .container {
                flex-direction: row;
                justify-content: flex-start;

                .text {
                    flex: 1 0 50%;
                    margin: 0 auto;
                    text-align: left;
                }

                .image {
                    flex: 0 0 50%;

                    .img {
                        display: block;
                        margin: 0 auto;
                    }
                }

            }

            &.img-left {
                .container {
                    justify-content: space-between;

                    .text {
                        margin: 0;
                        padding: 0 rem(10px);
                    }
                }

            }
        }
    }

    @media screen and (min-width: 768px) {
        .text-image {
            padding: rem(30px) rem(15px);

            &.has-background {
                display: flex;
                justify-content: center;
                min-height: rem(480px);
            }

            .container {
                justify-content: space-between;

                .img {
                    max-width: rem(300px);
                }
            }
        }
    }

    @media screen and (min-width: 992px) {
        .text-image {
            .container {
                .img {
                    margin-left: rem(80px);
                }
            }

            &.img-left {
                .img {
                    margin-right: rem(80px);
                }
            }
        }
    }

    @media screen and (min-width: 1280px) {
        .text-image {
            .container {
                padding: 0 rem(15px);
            }
        }
    }
