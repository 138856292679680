@use '../variables' as *;

/* ==========================================================================
   Radio Button / Checkbox / Inputs
   ========================================================================== */

.radio-container,
.checkbox-container {
    align-items: center;
    cursor: pointer;
    margin-bottom: rem(12px);
    padding-left: rem(30px);
    position: relative;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    input {
        cursor: pointer;
        height: rem(1px);
        opacity: 0;
        position: absolute;
        width: 1px;

        &:checked {
            &~.checkmark {
                &::after {
                    display: block;
                }
            }
        }
    }

    .checkmark {
        background-color: $white;
        border: rem(2px) solid $black;
        border-radius: 50%;
        height: rem(20px);
        left: 0;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        transition: 0.5s all;
        width: rem(20px);

        &::after {
            background: $red;
            border-radius: 50%;
            content: '';
            display: none;
            height: 100%;
            left: 0;
            position: absolute;
            top: 0;
            width: 100%;
        }
    }

    &:hover {
        input {
            &~.checkmark {
                background-color: $shadow;
            }
        }
    }

    &.error {
        .checkmark {
            border-color: $red;
        }
    }
}

.checkbox-container {
    font-weight: revert;

    input {
        left: rem(10px);
        top: rem(20px);
    }

    .checkmark {
        border-radius: 0;

        &::after {
            background: none;
            border: solid $red;
            border-radius: 0;
            border-width: 0 rem(3px) rem(3px) 0;
            height: rem(12px);
            left: rem(5px);
            top: 0;
            -webkit-transform: rotate(45deg);
            -ms-transform: rotate(45deg);
            transform: rotate(45deg);
            width: rem(7px);
        }
    }
}

.custom-input-field-wrapper {
    margin-bottom: rem(15px);

    .form-control {
        margin-bottom: 0;
    }

    .validation-message {
        color: $dark-red;
        margin-top: rem(5px);
    }
}



/* ==========================================================================
   Contact form
   ========================================================================== */
@mixin custom-textarea() {
    background-color: $light-grey;
    border: rem(1px) solid $light-grey;
    border-radius: 0;
    box-shadow: none;
    display: block;
    font-size: inherit;
    line-height: rem(32px);
    margin-bottom: rem(15px);
    padding: rem(5px) rem(10px);
    transition: 0.25s background-color;
    width: 100%;

    &:focus {
        background-color: $white;
        border: rem(1px) solid $blue;
        -webkit-box-shadow: inset 0 rem(1px) rem(1px) rgba(0, 0, 0, 0.075), 0 0 rem(8px) rgba(102, 175, 233, 0.6);
        box-shadow: inset 0 rem(1px) rem(1px) rgba(0, 0, 0, 0.075), 0 0 rem(8px) rgba(102, 175, 233, 0.6);
        outline: 0;
    }
}

.contact-form,
.form {
    .form-panel-heading {
        padding: rem(10px) 0;

        &.border {
            border-bottom: rem(1px) solid $black;
        }
    }

    fieldset {
        background-color: $white;
        border: none !important; // Had to overwrite style from idorsia-bootstrap
        padding: rem(10px) 0 !important; // Override bootstrap style
    }

    .radio-container,
    .checkbox-container {
        margin-right: rem(20px);
    }

    .checkbox-container {
        .checkmark {
            top: rem(5px);
            transform: none;
        }
    }

    .form-group {
        margin-bottom: 0;
        padding-bottom: 0;
    }

    .phone-container {
        margin-top: rem(15px);
    }

    label.control-label {
        & + textarea {
            @include custom-textarea();
        }
    }
    /* Errors */

    .has-error {
        .control-label {
            color: $dark-red;
        }

        .form-control,
        .checkmark {
            border-color: $dark-red;

            &:focus {
                -webkit-box-shadow: inset 0 rem(1px) rem(1px) rgba(0, 0, 0, 0.075), 0 0 rem(6px) #ce8483;
                box-shadow: inset 0 rem(1px) rem(1px) rgba(0, 0, 0, 0.075), 0 0 rem(6px) #ce8483;
            }
        }

        .checkbox-container {
            color: $dark-red;
        }

        .form-control-feedback {
            bottom: 0;
            color: $dark-red;
            right: rem(15px);
            top: inherit;

        }
    }

    .alert-warning {
        margin-bottom: rem(15px);

        &__content {
            display: flex;
            flex-direction: column;

            p {
                flex: 1;
            }

            .checkbox-container {
                flex: 0 0 50%;
            }

            p {
                color: inherit;
                font-weight: bold;
            }

            .checkbox-container {
                margin: rem(10px) 0 0;
                padding-left: rem(30px);
            }
        }
    }
}

.form-control {
    @include custom-textarea();
}

.btn-container {
    margin-top: rem(15px);
    padding: 0 rem(15px);

    &.no-padding {
        padding: 0;
    }

    .btn {
        &:first-child {
            margin-right: rem(10px);
        }
    }
}

// Buttons
.btn-red-primary,
.btn-red-secondary {
    background-color: $red;
    border: rem(2px) solid $red;
    border-radius: rem(5px);
    color: $white;
    font-size: inherit;
    min-width: rem(84px);
    transition: 0.5s all;
    width: auto;

    &:hover {
        background-color: $white;
        color: $red;
    }
}

.btn-red-secondary {
    background-color: $white;
    color: $red;
    margin-right: rem(10px);

    &:hover {
        background-color: $red;
        color: $white;
    }
}

.search-iframe-wrapper {
    margin-top: rem(120px);
}

/* ==========================================================================
+   Responsive
+   ========================================================================== */

@media (min-width: 600px) {
    .contact-form,
    .form {
        .alert-warning {
            &__content {
                flex-direction: row;

                p,
                .checkbox-container {
                    margin: 0;
                    padding: 0 rem(15px) 0 rem(30px);
                }
            }
        }
    }
}

@media (min-width: 768px) {
    .contact-form,
    .form {
        .phone-container {
            margin-top: 0;
        }

        fieldset {
            padding: rem(15px) 0 !important; // needs to add important to override boostrap style
        }
    }

    .form-horizontal .control-label {
        padding-top: rem(4px);
    }
}

div.col-sm-12:has(input.g-recaptcha) {
    display: flex;
}

@media only screen and (max-width: 767px) {
    .search-iframe-wrapper {
        margin-top: 0;
    }
}
