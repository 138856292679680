@charset "utf-8";

@use '../variables' as *;

/* base include */
// doc:
//  basic html
//  Overrides and colours
//  Keep in order

/* Safari 10.1+ (alternate method to force font on * for safari and ios) */
@media not all and (min-resolution: 0.001dpcm) {
  @supports (-webkit-appearance: none) {
    * {
      font-family: $font-stack-default;
      line-height: 1.15; /* 1 */
      -webkit-text-size-adjust: 100%; /* stylelint-disable-line */
    }

    // circle pagination Muli center.
    a.page-pagination__options__item {
      padding-top: 0.7rem;
    }
  }
}
// END Safari

/* base include END */
.sr-only {
  visibility: hidden;
}

body {
  background-color: $white;
  color: $black;
  font-family: $font-stack-default;
  font-weight: 300;
  line-height: 1.7; //34px;
  text-align: left;
}

img {
  max-width: 100%;
}
