@use '../variables' as *;

// Modal

.modal {
  &.fade {
    &.show {
      opacity: 1;
    }

    .modal-dialog-centered {
      align-items: center;
      display: flex;
      height: 100%;
      justify-content: center;
      margin: 0 auto;
      max-width: rem(400px);
      transform: none;

      .modal-content {
        border: none;
        border-radius: 0;
        max-height: 100%;
        width: 100%;

        .modal-header {
          border: none;
          border-radius: 0;
          padding: rem(20px) rem(60px) rem(20px) rem(20px);
          position: relative;

          .modal-title {
            font-size: rem(20px);
            font-weight: 500;
          }

          .close {
            height: rem(24px);
            opacity: 1;
            position: absolute;
            right: rem(20px);
            top: rem(22px);
            width: rem(24px);

            &::before,
            &::after {
              background-color: $black;
              content: ' ';
              height: rem(24px);
              left: rem(12px);
              position: absolute;
              top: 0;
              width: rem(2px);
            }

            &::before {
              transform: rotate(45deg);
            }

            &::after {
              transform: rotate(-45deg);
            }
          }
        }

        .modal-body {
          max-height: calc(100vh - rem(70px));
          overflow-y: auto;
          padding: 0 rem(20px) rem(20px);

          ul {
            &.basic-list {
              li {
                background-size: rem(20px);
                margin-bottom: rem(10px);
                padding-left: rem(30px);
              }
            }
          }

          & > img {
            margin-top: rem(10px);
            max-width: 50%;
          }

          &.img-left {
            & > img {
              margin-bottom: rem(10px);
              order: 1;
            }

            .text {
              order: 2;
            }
          }

          /* Sub components */

          iframe,

          > div,

          > article {
            width: 100%;
          }

          /* Video */
          .process-video {
            display: block;
            margin: 0 auto;
            overflow: hidden;
            position: relative;
            width: 100%;

            &::before {
              content: '';
              display: block;
              padding-top: 56.25%;
            }

            &__image {
              display: none;
            }

            &__iframe {
              border: 0;
              bottom: 0;
              height: 100%;
              left: 50%;
              position: absolute;
              top: 0;
              transform: translateX(-50%);
              width: 100%;
            }
          }

          .nav-tabs {
            margin-top: 0;
          }

          .padding-lr-15,
          .text-image {
            padding: 0;
          }

          .container,
          .template.row {
            width: 100%;
          }
        }
      }
    }
  }
}

.modal-backdrop.show {
  opacity: 0.3;
}

.modal-open {
  .modal {
    overflow: hidden;
  }
}

// Scroll bar
.modal-body::-webkit-scrollbar {
  width: rem(4px);
}

.modal-body::-webkit-scrollbar-thumb {
  background-color: $dark-grey;
  border-radius: rem(100px);
}

.modal-body::-webkit-scrollbar-track {
  background-color: $light-grey-200;
}

/* ==========================================================================
  Responsive
  ========================================================================== */

  @media (min-width: 576px) {
    .modal-dialog-centered {
        min-height: calc(100% - (1.75rem * 2));
    }
  }

  @media (min-width: 768px) {
    .modal.fade {
      .modal-dialog-centered {
        max-width: rem(680px);
        width: auto;

        .modal-content {
          .modal-body {
            & > img {
                margin-left: rem(10px);
            }

            &.img-left > img {
              margin-right: rem(10px);
            }

            &.flex-column {
              align-items: inherit;
              flex-direction: column;
            }
          }
        }
      }
    }
  }

  @media (min-width: 1280px) {
    .modal.fade:not(.has-video) {
      .modal-dialog-centered {
        max-width: rem(800px);
      }
    }
  }
