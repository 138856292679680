@use '../variables' as *;

/* components include */
// doc:
//  layout block classes
//  Try to use symantec html first
//  Follow naming conventions

header,
menu,
nav,
main,
aside,
footer {
  display: block;
}

/* components include END */
