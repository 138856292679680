@use '../variables' as *;

#header {
  // Tweaking old css Starts Here

  position: relative;
  z-index: 20;

  .navbar {
    box-shadow: 0 0 rem(12px) rem(6px) rgba(0, 0, 0, 0.15);
    margin: 0;

    &.no-shadow {
      box-shadow: none;
    }
  }

  .navbar-collapse {
    padding-left: 0;
    padding-right: 0;
  }

  .navbar-brand {
    float: none;
    font-size: rem(18px);
    height: auto;
    line-height: rem(20px);
    margin-left: rem(-9px);
    padding: 0;
  }

  .navbar-main-wrapper {
    float: none;
    margin: 0;
  }

  .menu-first-section {
    border-bottom: none;
    padding: 0;
  }

  .menu-second-section .navbar-nav {
    float: none;
  }

  &.header-two-level {
    max-height: inherit;
    min-height: inherit;
  }

  &.no-margin {
    margin: 0;
  }

  // Top Header Starts Here
  .top-header {
    align-items: center;
    display: flex;
    justify-content: space-between;

    &::before {
      content: ' ';
      display: none;
    }

    &::after {
      clear: none;
      content: ' ';
      display: none;
    }
  }

  .top-nav-actions {
    display: flex;
    list-style-type: none;
    margin: 0;
    padding: 0;

    > li {
      > a {
        cursor: pointer;
        margin: 0 rem(7px);
        padding-bottom: rem(5px);

        > span {
          border: rem(3px) solid transparent;
          display: none;
        }

        > img {
          height: rem(20px);
          width: rem(20px);
        }

        &:hover {
          border-bottom: rem(5px) solid $border;
          text-decoration: none;
        }

        &:focus {
          text-decoration: none;
        }
      }
    }

    > li.active {
      > a {
        border-bottom: rem(5px) solid $black;
      }
    }
  }

  .top-nav-submenu {
    background-color: $white;
    box-shadow: 0 rem(7px) rem(10px) 0 rgba(0,0,0,0.3);
    display: none;
    flex-direction: row;
    flex-wrap: wrap;
    left: 0;
    padding: rem(25px) rem(50px);
    position: absolute;
    right: 0;
    top: rem(68px);
    z-index: 2;

    &__header {
      display: flex;
      justify-content: space-between;
      padding: 0 rem(15px);
      max-width: 1280px;
      margin: 0 auto;

      button {
        position: absolute;
        top: 30px;
        right: 26px;
        background: $white;
        border: none;
        outline: 0;

        i {
          color: black;
        }
      }
    }

    &__content {
      display: flex;
      height: 100%;
      margin: 0 auto;
      max-width: 1280px;
      padding: rem(10px) rem(15px);

      &__main-section {
        .tp-inner-box {
          align-items: center;
          display: flex;
          height: 100% !important;
          justify-content: space-between;
          width: 100%;
        }
      }

      &__icon-section {
        img {
          width: auto;
          height: auto;
          max-height: rem(120px);
          max-width: rem(120px);
        }
      }
    }

    &__footer {
      display: flex;
      justify-content: flex-end;
      margin-top: rem(70px);
      padding: 0 rem(38px);

      button {
        background: $orange;
        border: none;
        border-radius: rem(5px);
        color: $white;
        outline: 0;
        padding: rem(5px) rem(20px);

        &.prev {
          background: $white;
          border: rem(1px) solid $orange;
          color: $orange;
          margin-right: rem(20px);
        }

        &:disabled {
          cursor: not-allowed;
          opacity: 0.5;
        }
      }
    }

    &.map-content {
      .top-nav-submenu__content {
        margin-top: 0;
        padding: 0;

        &__main-section {
          flex: 0 0 100%;
          min-width: 100%;
        }
      }
    }
  }

  // Search Nav
  .tp-search-box {
    width: rem(900px);

    input {
      font-size: rem(32px);
    }
  }

  // Share Nav
  .tp-share-box {
    ul {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      list-style-type: none;
      padding: 0;

      li {
        margin: 0 rem(3px);
        padding: rem(10px) rem(20px);

        a {
          align-items: center;
          display: flex;
          text-decoration: none;
        }
      }
    }
  }

  // Contact Nav

  .tp-contact-box {
    width: rem(900px);

    &__body {
      display: flex;
      flex-wrap: wrap;
      align-items: baseline;
    }

    &__formItem {
      flex: 0 0 33.333%;
      margin: rem(10px) 0;
      width: 33.333%;

      &__input {
        cursor: pointer;

        .form-control {
          background: none;
          border: none;
          box-shadow: none;
          cursor: pointer;
          display: grid;
          gap: rem(8px);
          grid-template-columns: rem(16px) auto;

          line-height: 1.5em;
          align-items: center;
          word-break: break-word;
        }

        span {
          margin-left: rem(10px);
        }

        input[type='radio'] {
          appearance: none;
          background-color: $white;
          border: rem(2.4px) solid currentColor;
          border-radius: 50%;
          color: currentColor;
          cursor: pointer;
          display: grid;
          font: inherit;
          height: rem(27px);
          margin: 0 rem(10px) 0 0;
          place-content: center;
          //transform: translateY(3px);
          width: rem(27px);

          &::before {
            border-radius: 50%;
            box-shadow: inset rem(16px) rem(16px) red;
            content: '';
            height: rem(17px);
            transform: scale(0);
            transition: 120ms transform ease-in-out;
            width: rem(17px);
          }

          &:checked::before {
            transform: scale(1);
          }

          &:focus {
            outline: none;
          }
        }
      }

      &__info {
        display: none;
      }
    }
  }

  .tp-location-box {
    width: 100%;
    height: 100%;
  }

  #contact-panel-two {
    display: none;

    h3 {
      margin-bottom: rem(50px);
    }

    p {
      margin: rem(30px) 0;
    }
  }

  .navbar-toggle {
    float: none;
    margin: 0 rem(7px);
    padding: 0;
    position: static;
  }

  // Dropdown
  .dropdown-wrap-section > .container {
    padding: rem(20px) rem(15px);
  }

  .dropdown-wrap-section {
    background: $white;
    border: rem(1px) solid $orange;
    display: none;
    height: auto;
    left: 0;
    position: absolute;
    right: 0;
    top: 100%;
    width: 100%;
    z-index: 99;
  }

  .menu-second-section .dropdown:hover .dropdown-wrap-section {
    display: block;
  }

  .dropdown-info h1,
  .dropdown-info h2 {
    font-size: rem(24px);
  }

  .menu-second-section .dropdown-menu {
    background: $white;
    border: none;
    border-left: rem(1px) solid $orange;

    li {
      & > a,
      & > button.link {
        color: $black !important;
        display: inline-block;
        font-weight: 500;
        padding: rem(5px) rem(10px) rem(5px) rem(28px);
        position: relative;

        &::before {
          left: 0;
          position: absolute;
        }

        &::after {
          height: rem(4px);
          transition: 0.2s all;
        }

        &:hover {
          &::after {
            background: $border !important;
          }
        }
      }

      &.active {
        & > a,
        & > button.link {
          &::after {
            background: $black !important;
          }
        }
      }
    }
  }

  .dropdown-menu {
    position: static;
  }

  .dropdown-wrap-section > .container > .dropdown-info {
    flex: 0 0 50%;
    min-width: 50%;
    padding-right: rem(30px);
  }

  .dropdown-wrap-section > .container > .dropdown-menu {
    flex: 0 0 50%;
    min-width: 50%;
  }

  .navbar-default {
    background: $white;
    height: auto;
    z-index: 10000;
  }

  .navbar.navbar-default .navbar-nav ul.level-3 li a::before,
  .menu-second-section .dropdown-menu li > a::before,
  .navbar.navbar-default .navbar-nav ul.level-3 li button.link::before,
  .menu-second-section .dropdown-menu li > button.link::before {
    content: ' > ';
    display: inline-block;
    margin-right: rem(10px);
  }

  .sub-menu-mobile .summary {
    color: $black;
    font-size: 0.9rem;
    font-weight: 600;
    margin-bottom: rem(10px);
    margin-left: rem(30px);

    &::before {
      content: ' > ';
      display: inline-block;
      margin-right: rem(10px);
    }
  }

  /**---------------------------------------
   **          RESPONSIVE
   *---------------------------------------**/

  @media (min-width: 768px) {
    .navbar-header {
      float: none;
    }

    .menu-first-section .navbar-nav li:not(.active) a:not(:hover)::after {
      background: transparent;
      content: '';
      display: block;
      margin-top: rem(2px);
      width: 100%;
    }

    .navbar-nav {
      display: flex;
      float: none;
      margin-left: rem(15px);

      & > li {
        display: flex;
        float: none;
        position: static;
      }

      &.level-1 {
        li {
          padding: rem(10px) rem(15px);

          &:first-child {
            padding-left: 0;
          }

          &:last-child {
            padding-right: 0;
          }
        }

        a,
        button.link {
          color: $black;
          line-height: rem(20px);
          padding: 0;

          &::after {
            background: transparent;
            content: '';
            display: block;
            height: rem(4px);
            margin-top: rem(2px);
            width: 100%;
          }

          &:hover {
            text-decoration: none;

            &::after {
              background: $border;
            }
          }
        }

        .active {
          a,
          button.link {
            &::after {
              background: $black;
            }
          }
        }
      }
    }

    .fixed-header {
      background: $white;
      height: auto;
      left: 0;
      position: fixed;
      right: 0;
      top: 0;
      // transform: translateY(-73px);
      transition: transform 0.6s cubic-bezier(0.25, 0.8, 0.25, 1);
    }

    .fixed-header-translate {
       transform: translateY(-73px);
    }

    .fixed-header-up {
      left: 0;
      position: fixed;
      right: 0;
      transform: translateY(0);
      transition: transform 0.6s cubic-bezier(0.25, 0.8, 0.25, 1);
    }

    .top-nav-actions li > a > span {
      display: inline-block;
    }

    .sub-menu-mobile {
      display: none !important;
    }

    .top-nav-actions > li {
      margin: 0 rem(12px);

      & > a {
        cursor: pointer;
      }
    }

    .navbar-fixed-top .navbar-collapse {
      display: none;
    }

    .menu-second-section {
      padding: 0;

      .navbar-nav {
        a,
        button.link {
          color: $white;
          line-height: rem(20px);
          padding: rem(10px) rem(15px) rem(7px);

          &::after {
            background: transparent;
            content: '';
            display: block;
            height: rem(4px);
            margin-top: rem(2px);
            width: 100%;
          }

          &:hover {
            text-decoration: none;

            &::after {
              background: rgba(255, 255, 255, 0.5) !important;
            }
          }
        }
      }
    }
  }

  @media screen and (min-width: 992px) {
    .navbar-nav > li:first-of-type > a {
      padding-left: 0;
    }
  }

  @media screen and (max-width: 1023px) {
    .tp-contact-box {
      width: 100%;
    }
  }

  @media (max-width: 819px) {
    margin-bottom: 0;

    .fixed-header {
      left: 0;
      position: fixed;
      right: 0;
      top: 0;
      z-index: 1030;
    }

    .fixed-header .navbar-collapse {
      max-height: rem(340px);
    }

    .top-header {
      border-bottom: none;
      box-shadow: 0 rem(5px) rem(16px) rem(-2px) rgba(0, 0, 0, 0.2);
    }

    .tp-contact-box__formItem {
      flex: none;
      width: 100%;
    }

    .tp-contact-box {
      width: 100%;
    }

    .tp-share-box ul li a span:last-child {
      display: none;
    }

    .tp-share-box ul li {
      margin: 0;
      padding: 0;
    }

    .navbar.navbar-default {
      .navbar-nav {
        li a {
          width: 100%;

          &:hover::after {
            display: none
          }
        }

        ul.level-2 {
          background-color: $white;
          padding: rem(10px) 0;

          & > li {
            border-left: rem(3px) solid $black;
            color: $black;
            margin-left: rem(30px);
            width: 90%;
          }

          li {
            a {
              color: $black;
            }

            button.link {
              color: $black;
              font-size: rem(14px);
              line-height: rem(21px);
              padding: rem(10px) rem(15px);
              position: static;
              text-align: left;
              width: 100%;
            }

            button.arrow::before {
              color: $black;
            }
          }
        }
      }
    }

    .navbar-collapse.collapse.show {
      border-bottom: rem(6px) solid $orange;
    }

    .collapse.show {
      margin-bottom: 0;
      padding-bottom: 0;
    }

    .navbar-fixed-top .navbar-collapse {
      box-shadow: 0 rem(11px) rem(21px) rem(1px) rgba(0, 0, 0, 0.2);
      max-height: rem(340px);
    }

    .navbar-collapse.show {
      overflow-y: auto;
    }

    .top-nav-submenu__content__icon-section {
      display: none;
    }

    .top-nav-submenu__content {
      display: block;
      padding: rem(10px) 0;
    }

    .top-nav-submenu__content__main-section {
      width: 100%;
    }

    .tp-search-box {
      width: 100%;

      input {
        font-size: rem(16px);
      }
    }

    .top-nav-submenu {
      padding: rem(25px) rem(21px);
      top: rem(80px);
    }

    .nav-side-menu ul {
      li .arrow {
        color: $white;
        font-size: rem(14px);
        font-weight: bold;
        min-height: rem(41px);
        padding: 0 rem(15px);
        position: relative;
        text-align: left;
        width: 100%;

        &::before {
          line-height: rem(41px);
          padding: 0 rem(12px);
          position: absolute;
          right: 0;
          top: 0;
        }
      }

      &.first-sub-menu li .arrow {
        color: $black;
      }
    }

    .navbar.navbar-default {
      .navbar-nav {
        ul {
          &.level-2 li a {
            padding-left: rem(15px);
            width: 100%;
          }

          &.level-3 li button {
            font-family: 'Ubuntu', sans-serif;
            font-size: rem(14px);
            font-weight: 400;
            position: relative;

            &::before {
              content: ' > ';
              display: inline-block;
              height: rem(42px);
              margin-right: rem(10px);
              padding: 0;
              position: static;
            }

            &.collapsed {
              &::after {
                content: '\e259';
              }
            }
          }

          &.level-1 {
            button.link {
              color: $white;
              line-height: rem(21px);
              padding: rem(10px) rem(15px);
              text-align: left;
              width: 100%;
            }
          }
        }
      }
    }
  }
}

// Only when Progress Bar is displayed
.has-header {
  #header {
    .navbar {
      margin: 0;
    }

    .navbar-collapse {
      &.fixed-header {
        box-shadow: none;
      }
    }
  }
}

#header.fixed-header {
  margin-bottom: 0;
}

@media (min-width: 820px) {
  .has-scrollbar.modal-open #header .fixed-header {
    padding-right: rem(17px);
  }
}

// Scroll bar
.top-nav-submenu__content::-webkit-scrollbar {
  width: rem(4px);
}

.top-nav-submenu__content::-webkit-scrollbar-thumb {
  background-color: $dark-grey;
  border-radius: rem(100px);
}

.top-nav-submenu__content::-webkit-scrollbar-track {
  background-color: $light-grey-200;
}

/* Burger */

@media (max-width: 819px) {
  .navbar-toggle {
    .idorsia-icon-bar {
      background-color: $black;
      border-radius: rem(1px);
      display: block;
      height: rem(2px);
      transition: all 0.2s;
      width: rem(22px);

      & + .idorsia-icon-bar {
        margin-top: rem(4px);
      }
    }

    &.collapse {
      .idorsia-icon-bar {
        &.top-bar {
          transform: rotate(0);
        }

        &.middle-bar {
          opacity: 1;
        }

        &.bottom-bar {
          transform: rotate(0deg);
        }
      }
    }

    &:not(.collapsed) {
      .idorsia-icon-bar {
        &.top-bar {
          transform: rotate(45deg);
          transform-origin: 10% 10%;
        }

        &.middle-bar {
          opacity: 0;
        }

        &.bottom-bar {
          transform: rotate(-45deg);
          transform-origin: 10% 90%;
        }
      }
    }
  }
}

@media (max-width: 819px) {
  #header .top-nav-submenu__header {
    padding: 0;
  }
}
