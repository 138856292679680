@use '../variables' as *;

/* helpers include */

html,
body {
  color: $black;
  font-size: $base-font-size; // Used for 1rem size - all rem sizes are *16px;
  -webkit-font-smoothing: antialiased;
}



/* helpers include END */
