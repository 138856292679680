@use '../variables' as *;

.media-news h3 {
    margin: 0;
    padding: 0;
}

.alert-news-media {
    margin-top: rem(30px);
}

.media-news .display-date {
    float: left;
    padding-right: rem(10px);
}

.media-news .panel-heading {
    border-bottom: 1px solid $black;
    padding: 0;
}

.media-news .panel-heading .nav > li > a {
    color: $black;
    padding: rem(18px) rem(10px) rem(18px) 0;
}

.media-news .panel-heading .nav > li.disabled > a {
    cursor: default;
}

.media-news .panel-heading .nav > li.disabled:hover > a > h3 {
    color: $black;
}

.media-news .panel-heading .nav > li:not(.active):hover .border-decoration {
    opacity: 1;
}

.media-news .panel-heading .nav > li:not(.active) .border-decoration {
    filter: alpha(opacity=20);
    /*filter: gray;*/
    filter: grayscale(1);
    filter: grayscale(1);
    opacity: 0.2;
}

.media-news .panel-heading .nav > li:not(.active) > a > h3 {
    filter: alpha(opacity=20);
    /*filter: gray;*/
    filter: grayscale(1);
    filter: grayscale(1);
    opacity: 0.5;
}

.media-news .nav > li .border-decoration {
    height: rem(4px);
    margin-bottom: rem(-1px);
}

.media-news .navbar-default {
    background-color: transparent;
}

.media-news .nav > li > a:hover > h3 {
    color: #777777;
}

.media-news .navbar-default .navbar-nav > .active > a,
.media-news .navbar-default .navbar-nav > .active > a:focus,
.media-news .navbar-default .navbar-nav > .active > a:hover {
    background-color: transparent;
    width: 100%;
}

.media-news .view-news-events {
    float: right;
    padding-top: rem(25px);
    text-transform: none;
}
@media only screen and (max-width: 767px) {
    .media-news .view-news-events {
        float: left;
        padding-bottom: rem(15px);
        padding-top: rem(15px);
    }
}

.media-news-only h3 {
    float: left;
    margin: rem(20px) 0 rem(10px);
    padding: 0;
}

.media-news-only .display-date {
    float: left;
    padding-right: rem(10px);
}

.media-news-only .view-all {
    float: right;
    margin-right: rem(20px);
    margin-top: rem(20px);
}

.media-news-only .panel-heading {
    border-bottom: rem(1px) solid $black;
    padding: 0 0 rem(11px);
}

.media-news-only .panel-body {
    padding-top: rem(20px);
}

.media-news-only td.news-date {
    border-bottom: none;
    border-right: rem(5px);
    font-weight: normal;
    padding-right: rem(5px);
    white-space: nowrap;
}

.media-news-only td.news-link {
    border-bottom: none;
    font-weight: normal;
    padding: 0;
}

.media-news-only ul {
    padding-left: rem(15px);
}

.media-news-only li {
    list-style-type: none;
}
