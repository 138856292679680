@use '../variables' as *;

/* utilities include */
// doc:
//  utility short classes
//  Aim for single property or effect
//  Follow naming conventions

// Width

.w-100 {
  width: 25rem;
}

.w-max {
  width: 100%;
}

/* Page Wrapper to keep max width */
.wrapper {
  max-width: 90vw;
}

.d-flex {
  display: flex !important;
}

.d-block {
  display: block !important;
}

.d-none {
  display: none;
}

.text-italic {
  font-style: italic;
}

// Padding
.padding-top-20 {
  padding-top: rem(20px);
}

.title-container,
.padding-lr-15 {
  padding: 0 rem(15px);
}

.relative-position {
  position: relative;
}

.mandatory {
  color: $dark-red;
}

.alert-disclaimer {
  background-color: rgba(255, 51, 0, 0.2);

  &.error {
    border: rem(1px) solid $red;
  }
}

/* flex utils */

/* Flex Utilities */
.flex {
  display: flex !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-row {
  flex-direction: row !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.justify-content-evenly {
  justify-content: space-evenly !important;
}

.justify-content-right {
  justify-content: right !important;
}

.justify-content-left {
  justify-content: left !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.align-items-stretch {
  align-items: stretch !important;
}

.align-items-baseline {
  align-items: baseline !important;
}

.align-self-start {
  align-self: flex-start !important;
}

.align-self-center {
  align-self: center !important;
}

.align-self-end {
  align-self: flex-end !important;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.text-center {
  text-align: center;
}

/* Border decoration */
.border-bar {
  border-top: rem(5px) solid $black;
  padding: 0;
}

/* Simple Arrow Style */
.arrow {
  border: solid $black;
  border-width: 0 rem(3px) rem(3px) 0;
  display: inline-block;
  padding: rem(3px);

  &.right {
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
  }

  &.left {
    -webkit-transform: rotate(135deg);
    transform: rotate(135deg);
  }
}

// Background image if section has one

.background-image {
  display: none;
}

// General icon styling. Taken from idorsia's page
[class^='idorsia-icon-']::before,
 [class*=' idorsia-icon-']::before {
  display: inline-block;
  font-family: 'act_fa';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  line-height: 1em;
  margin-left: 0.2em;
  margin-right: 0.2em;
  speak: none;
  text-align: center;
  text-decoration: inherit;
  text-transform: none;
  width: 1em;
}

.text-white {
  color: $white;
}

.pd-0 {
  padding: 0;
}

.align-items-center {
  align-items: center;
}

iframe {
  border: none;
  width: 100%;
}

.filecontent-container {
  margin: rem(15px) auto;
  max-width: rem(1280px);
  padding: 0 rem(15px);
  width: 100%;
}

section.container {
  padding: 0;
}

p.error {
  color: $red;
  display: none;

  &.show {
    display: block;
  }
}

.panel-heading.border {
  border-bottom: rem(1px) solid $black;
}

.btn-no-style {
  background: none;
  border: none;
}

.embed-responsive {
  position: relative;
}

h2.section-title {
  font-size: rem(36px);
  font-weight: 500;
  margin-bottom: rem(20px);
}

.margin-bottom-20 {
  margin-bottom: rem(20px);
}

.link {
  background: none;
  border: none;
  color: #1e1e1e;
  font-weight: bold;

  &:hover {
    text-decoration: underline;
  }
}

.elem-disabled {
  pointer-events: none;
}

/* Added Bootstrap Icon coming from the library for buttons */
.arrow-nav button,
.arrow-nav-rev button,
.download-nav button,
.arrow-back-nav button,
.location-nav button,
.mail-nav button,
.phone-nav button,
.webcast-nav button,
.fax-nav button,
.voip-nav button {
  background: none;
  border: none;
  padding: 0;
}

.arrow-nav a::before,
.arrow-nav span::before,
.arrow-nav button::before,
.arrow-nav-rev a::before,
.arrow-nav-rev span::before,
.arrow-nav-rev button::before {
  color: #ff3300;
  content: '\e080';
  float: left;
  font-family: 'Glyphicons Halflings';
  font-size: rem(12px);
  margin-left: rem(-17px);
  margin-top: rem(3px);
  padding-right: rem(10px);
}

.arrow-nav-rev a::before,
.arrow-nav-rev span::before,
.arrow-nav-rev button::before {
  content: '\e079';
}

.download-nav a::before,
.download-nav span::before,
.download-nav button::before {
  content: '\e025';
  float: left;
  font-family: 'Glyphicons Halflings';
  font-size: rem(15px) !important;
  font-weight: normal;
  margin-left: rem(-19px);
  margin-top: 0;
  padding-right: rem(9px);
}

.location-nav button::before {
    content: '\e062';
    float: left;
    font-family: 'Glyphicons Halflings';
    font-size: rem(17px);
    font-weight: normal;
    margin-left: rem(-19px);
    margin-top: rem(-2px);
    padding-right: rem(7px);
}

.arrow-back-nav a::before,
.arrow-back-nav span::before,
.arrow-back-nav button::before,
.mail-nav a::before,
.mail-nav span::before,
.mail-nav button::before,
.phone-nav a::before,
.phone-nav span::before,
.phone-nav button::before,
.fax-nav a::before,
.fax-nav span::before,
.fax-nav button::before {
    content: '\2709';
    float: left;
    font-family: 'Glyphicons Halflings';
    font-size: rem(16px);
    font-weight: normal;
    margin-left: rem(-19px);
    margin-top: rem(1px);
    padding-right: rem(8px);
}

.arrow-back-nav a::before,
.arrow-back-nav span::before,
.arrow-back-nav button::before {
  content: '\e132';
}

.phone-nav a::before,
.phone-nav span::before,
.phone-nav button::before {
    content: '\e182';
}

.webcast-nav button::before {
    content: '\e038';
    float: left;
    font-family: 'Glyphicons Halflings';
    font-size: rem(17px);
    font-weight: normal;
    margin-left: rem(-19px);
    margin-top: rem(-2px);
    padding-right: rem(7px);
}

.fax-nav a::before,
.fax-nav span::before,
.fax-nav button::before {
    content: '\e045';
}

.voip-nav button::before {
    content: '\e145';
    float: left;
    font-family: 'Glyphicons Halflings';
    font-size: rem(17px);
    font-weight: normal;
    margin-left: rem(-19px);
    margin-top: rem(-2px);
    padding-right: rem(7px);
}

.fax-nav button::before,
.arrow-back-nav button::before,
.phone-nav button::before {
  margin-left: rem(-25px);
}

.modal.redirect-modal .modal-dialog-centered .modal-content .modal-body {
  ul {
    list-style: disc;
    padding: revert;
  }
}

/* Generic ul */
.teaser-box,
.carousel,
.text-image,
.generic-text,
.introduction-text,
.content-faq {
    @include generic-ul()
}

/* ==========================================================================
   Responsive
   ========================================================================== */

@media (min-width: 768px) {
  .md\:w-100 {
    width: 25rem;
  }

  .background-image {
    background-repeat: no-repeat;
    background-size: auto 100%;
    display: block;
    height: rem(480px);
    left: 0;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 100%;
    z-index: 1;

    &.position-left {
      background-position: left;
    }

    &.position-center {
      background-position: center;
    }

    &.position-right {
      background-position: right;
    }

  }
}

@media (min-width: 992px) {
  // MAGAWS-37: Clear after only on medium size screen
  .clear-md {
      clear: both;
  }
}

/* utilities include END */
