@use '../variables' as *;

.social-media-feeds {
  margin-bottom: rem(50px);

  .feedItem {
    margin-bottom: rem(30px);

    &:last-child {
      margin-bottom: 0;
    }

    .post-heading {
      margin-bottom: rem(10px);

      a {
        color: $blue;
      }
    }

    .post-body {
      margin-bottom: rem(10px);

      a {
        color: $blue;
      }
    }
  }

  .carousel {
    height: 100%;

    &-inner {
      display: flex;
    }

    &-item {
        display: block;
        opacity: 0;
        visibility: hidden;

        &.active {
        opacity: 1;
            visibility: visible;
        }
    }

    .carousel-indicators {
      bottom: rem(-40px);
      margin: rem(10px) auto 0;
      position: absolute;
      top: auto;
      transform: translateX(-50%);
    }
  }
}

/* =========================================
   Responsive
   ========================================= */
   @media only screen and (max-width: 991px) {
    .social-media-feeds {
      margin-bottom: rem(30px);

      .panel {
        margin-bottom: 0;
      }

        .carousel {
          &-inner {
            display: block;
          }

          .carousel-indicators {
            bottom: initial;
            margin-top: 0;
            position: relative;
            width: 100%;
          }
        }


    }
  }
