@use '../variables' as *;

.quote {
    @include fading-entrance(0, 1s, unset, rem(20px));
    margin: rem(40px) 0;
    padding: rem(40px) rem(20px);
    position: relative;
    width: 100%;

    .container {
        align-items: center;
        display: flex;
        flex-direction: column;
        justify-content: center;

        img {
            @include fading-entrance(0, 0.5s, 0.5s, 0);
            margin: 0 rem(20px) rem(40px);
            max-width: rem(200px);
            width: 100%;
        }

        blockquote {
            @include fading-entrance(0, 1s, 0.75s, 20px);
            max-width: rem(400px);
            text-align: center;

            small {
                color: $white;
                font-size: rem(16px);
            }
        }
    }

    blockquote {
        color: $white;

        small {
            color: $white;
        }
    }

    &.active {
        opacity: 1;
        transform: translate3d(0, 0, 0);

        img {
            opacity: 1;
        }

        blockquote {
            opacity: 1;
            transform: translate3d(0, 0, 0);
        }
    }

    &.background-img {
        background-position: top center;
        background-size: cover;
        padding-top: rem(280px);

        blockquote {
            margin: 0;
        }

        &.text-centered {
            padding: rem(160px) rem(20px);
        }

        &.text-left {
            padding: rem(40px) rem(20px) rem(280px);
        }
    }

    &.text-left {
        .container {
            justify-content: center;

            img {
                margin: rem(40px) rem(20px) 0;
                order: 2;
            }

            blockquote {
                order: 1;
            }
        }
    }
}

/* Color theme */

.theme-orange {
    .quote {
        @include linear-gradient(#F5b92d, #F1812d, #ee442f);
    }
}

.theme-blue {
    .quote {
        @include linear-gradient(#43a3e1, #8da5e9, #c9a8ef);
    }
}

.theme-pink {
    .quote {
        @include linear-gradient(#f59b2c, #e40d79, #8f1d80);
    }
}

.theme-purple {
    .quote {
        @include linear-gradient(#e72c9d, #811a83, #392784);
    }
}

.theme-green {
    .quote {
        @include linear-gradient(#f2e30d, #76c437, #42b649);
    }
}

/* ==========================================================================
   Responsive
   ========================================================================== */

@media (min-width: 768px) {
    .quote {
        padding: rem(60px) rem(30px);

        .container {
            flex-direction: row;
            justify-content: space-between;

            img {
                margin: 0 rem(40px);
            }

            blockquote {
                text-align: left;
            }
        }

        &.background-img {
            align-items: center;
            background-position: left;
            display: flex;
            height: rem(300px);
            padding-top: rem(60px);

            .container {
                justify-content: flex-end;
                width: 100%;
            }

            &.text-centered {
                padding: rem(60px) rem(30px);

                .container {
                    justify-content: center;

                    blockquote {
                        text-align: center;
                    }
                }
            }

            &.text-left {
                padding: rem(60px) rem(30px);

                .container {
                    justify-content: flex-start;

                    blockquote {
                        text-align: right;
                    }
                }
            }
        }

        &.text-left {
            .container {
                img {
                    margin: 0 rem(40px);
                }
            }
        }
    }
}

@media (min-width: 992px) {
    .quote {
        &.background-img {
            height: rem(400px);
        }

        .container {
            img {
                max-width: rem(260px);
            }

            blockquote {
                font-size: rem(24px);
                max-width: rem(500px);
            }
        }
    }
}

@media (min-width: 1280px) {
    .quote {
        .container {
            blockquote {
                font-size: rem(26px);
                max-width: rem(600px);
            }
        }

        &.background-img {
            .container {
                width: rem(1280px);

                blockquote {
                    max-width: rem(600px);
                }
            }
        }
    }
}

@media (min-width: 1600px) {
    .quote {
        &.background-img {
            height: rem(500px);
        }

        .container {
            img {
                max-width: rem(300px);
            }
        }
    }
}
