@use '../variables' as *;

.shareprice {
    .flexDisplay {
        align-items: flex-start;
        display: flex;
        flex-wrap: wrap;

        p {
            flex: 0 0 100%
        }
    }

    p {
        margin-bottom: rem(5px);

        &.price {
            font-size: 1.6em;
        }

        &.priceDiff {
            align-items: center;
            display: flex;
            font-size: 1.2em;

            .shareprice-arrow {
                margin-right: rem(15px);
            }
        }
    }

    .arrow-nav {
        padding: 0;

        a {
            padding-left: rem(15px);
            position: relative;

            &::before {
                content: '\e080';
                float: none;
                font-family: 'Glyphicons Halflings';
                font-size: rem(11px);
                left: 0;
                margin: 0;
                padding: 0 rem(2px) 0 0;
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
            }
        }
    }
}

/* ==========================================================================
   Responsive
   ========================================================================== */

@media screen and (min-width: 600px) {
    .shareprice {
        .flexDisplay {
            p {
                flex: 0 0 50%
            }
        }
    }
}
