@use '../variables' as *;

.text-tiles {
    padding: rem(15px) 0;
    position: relative;
    z-index: 1;

    .container {
        display: flex;
        flex-direction: column;
        padding: 0;
        z-index: 2;

        .text {
            flex: 0 0 50%;
            margin: 0 rem(5px);
            z-index: 2;
        }

        .tiles-list {
            list-style: none;
            margin-bottom: 0;
            margin-top: rem(10px);
            padding: 0;
            width: 100%;
            z-index: 2;

            .tile {
                box-shadow: 0 0 rem(12px) 0 rgba(0, 0, 0, 0.15);
                display: flex;
                flex-direction: column;
                margin: rem(5px);
                overflow: hidden;
                padding: 0;
                width: calc(50% - rem(10px));

                .tile-btn {
                    align-items: center;
                    background-color: transparent;
                    border: none;
                    display: flex;
                    flex: 1 0 100%;
                    flex-direction: column;
                    height: 100%;
                    padding: 0 rem(10px) rem(20px);
                    position: relative;
                    width: 100%;

                    img,
                    svg {
                        margin: rem(10px) 0 rem(20px);
                        width: rem(44px);
                    }

                    .frame-icon {
                        & + .tile-btn__text {
                            padding-top: rem(60px);
                        }
                    }

                    &__text {
                        align-items: center;
                        display: flex;
                        flex-direction: column;
                        margin-top: auto;

                        strong {
                            font-weight: 400;
                            order: 1;
                            text-align: center;
                        }

                        .idorsia-icon-plus {
                            font-size: rem(24px);
                            margin-right: rem(10px);
                            margin-top: auto;
                            order: 2;

                            &::before {
                                margin: 0;
                            }
                        }

                        &.small {
                            font-size: rem(18px);
                            line-height: rem(25px);

                            strong {
                                font-weight: 500;
                            }
                        }

                        &.medium {
                            font-size: rem(24px);
                            line-height: rem(34px);

                            .idorsia-icon-plus {
                                font-size: rem(30px);
                            }
                        }

                        &.large {
                            font-size: rem(30px);
                            line-height: rem(42px);

                            .idorsia-icon-plus {
                                font-size: rem(36px);
                            }
                        }
                    }

                    .frame-icon {
                        margin: 0;
                        position: absolute;
                        right: rem(10px);
                        top: rem(10px);
                        width: rem(30px);
                    }
                }

                .popupContent {
                    display: none;
                }

                &.min-height {
                    max-height: rem(330px);
                    min-height: rem(160px);
                }

                &.double-height {
                    max-height: rem(500px);
                    min-height: rem(330px);
                }

                &.triple-height {
                    min-height: rem(500px);
                }
            }

            &.flex {
                display: flex;
                flex-wrap: wrap;
            }

            &.grid-display {
                .tile {
                    &.double-height,
                    &.triple-height {
                        .tile-btn {
                            height: auto;
                        }
                    }
                }
            }
        }
    }

    &.tiles-left {
        .text {
            order: 2;
        }

        .tiles-list {
            margin-bottom: rem(10px);
            margin-top: 0;
            order: 1;
        }
    }
}

/* Modal */
.modal {
    &.tiles-modal {
        ul {
            .tile {
                padding-left: rem(30px);
                position: relative;

                img {
                    left: 0;
                    position: absolute;
                    top: rem(5px);
                    width: rem(18px);
                }
            }
        }
    }
}

/* Color Themes */

@each $theme, $color in $themes {
    .theme-#{$theme} {
        .text-tiles {
            .tiles-list {
                .tile {
                    background-color: $color;
                    border: rem(2px) solid $color;
                    color: $white;
                    transition: all 0.25s ease-in;

                    img,
                    svg {
                        filter: invert(96%) sepia(0%) saturate(7485%) hue-rotate(60deg) brightness(109%) contrast(101%);
                        transition-delay: 0.25s;
                    }

                    &:hover {
                        background-color: $white;
                        color: $black;

                        .idorsia-icon-plus {
                            color: $color;
                        }
                    }
                }
            }
        }
    }
}

@each $theme, $filter in $color-filters {
    // Tiles Images
    .theme-#{$theme} {
        .text-tiles {
            .tiles-list {
                .tile {
                    &:hover {
                        img,
                        svg {
                            filter: $filter;
                        }
                    }
                }
            }
        }
    }
}

/* ==========================================================================
   Responsive
   ========================================================================== */

@media (min-width: 768px) {
    .text-tiles {
        &.has-background {
            min-height: rem(480px);
        }

        .container {
            flex-direction: row;
            justify-content: center;
            padding: 0 rem(15px);

            .text {
                margin: 0;
                padding: 0 rem(10px);

                p {
                    margin-top: rem(10px);
                }
            }

            .tiles-list {
                width: 100%;

                .tile {
                    margin: rem(10px);
                    width: calc(50% - 20px);

                    .tile-btn {
                        img,
                        svg {
                            margin: rem(10px) 0 rem(20px);
                            width: rem(60px);
                        }
                    }

                    &.min-height {
                       max-height: rem(340px);
                    }

                    &.double-height {
                        max-height: rem(520px);
                        min-height: rem(340px);
                    }

                    &.triple-height {
                        min-height: rem(520px);
                    }
                }

                &.grid-display {
                    .tile {
                        &.min-height {
                            .tile-btn {
                                position: relative;
                            }
                        }
                    }
                }
            }
        }

        &.tiles-left {
            .text {
                margin-right: 0;
                padding-left: rem(10px);
            }
        }

        &.no-text {
            .container .tiles-list .tile {
                width: calc(25% - rem(20px));
            }
        }

    }

}
