html {
    font-size: 100%;
}

body {
    font-size: 16px !important;
    -webkit-font-smoothing: antialiased;
    font-family: "Ubuntu", sans-serif;
    color: #000;
}

header {
    max-height: 109px;
    min-height: 109px;
    background-color: #ffffff;
}
header.header-two-level {
    max-height: 153px;
    min-height: 153px;
}
header.header-one-level {
    max-height: 109px;
    min-height: 109px;
}

table {
    border: 0!important;
    border-spacing: 3px;
    border-collapse: separate!important;
}

table, tr, th, td {
    border: 0;
}

th, td {
    padding: 0 20px 2px 4px;
    border-bottom: 1px solid #000;
    vertical-align: top;
    border-top: none;
}

tr:first-child td {
    border-bottom: 0;
    font-weight: bold;
}

thead + tbody tr:first-child td {
    border-bottom: 1px solid #000;
    font-weight: 300;
}

th {
    border-bottom: 2px solid #000;
    font-weight: bold;
}

a {
    color: #000;
    font-weight: bold;
}
a:hover {
    color: black;
}
a.arrow {
    padding-left: 3px;
}
a.arrow:before {
    content: "\e080";
    font-family: "Glyphicons Halflings";
    font-size: 10px;
    float: left;
    margin-top: 4px;
    color: #ff3300;
}

p {
    color: #000;
}

h1.page-name {
    margin-top: 0;
}
.h3-override {
    margin-top: 0px;
}

.template {
    padding: 0px;
}
.row.template {
    margin-left: 0px;
    margin-right: 0px;
    padding: 0px 0px 20px 0px;
}
main.container {
    padding-left: 0px;
    padding-right: 0px;
}
.larger-text {
    font-size: 18px;
}
.text-bold {
    font-weight: bold;
}
.grey-separator {
    border-top: 1px solid black;
}
.m-0 {
    margin: 0;
}
.mt-0 {
    margin-top: 0;
}
.p-0 {
    padding: 0;
}
.pl-0 {
    padding-left: 0;
}
.indent-padding {
    margin-left: 15px;
    margin-right: 15px;
}
.override-padding-lr {
    padding: 0px 15px;
}
.top-padding {
    padding-top: 30px;
}
.bottom-padding {
    padding-bottom: 10px !important;
}
.border-decoration.bar {
    height: 5px;
    background: black;
}
.border-decoration.no-bar {
    background: transparent;
}

span.arrow-nav a {
    font-weight: bold;
}
span.arrow-nav a:before {
    margin-left: 0px !important;
}
span.arrow-nav-rev a:before {
    margin-left: 0px !important;
}
.arrow-nav.list-nav > li {
    padding-bottom: 2px;
    margin-bottom: 0;
}
.arrow-nav.list-nav li:before {
    content: "\e080";
    font-family: "Glyphicons Halflings";
    font-size: 10px;
    float: left;
    margin-top: 4px;
    margin-left: -17px;
    color: #ff3300;
}
.arrow-nav a:before,
.arrow-nav span:before {
    content: "\e080";
    font-family: "Glyphicons Halflings";
    font-size: 11px;
    float: left;
    margin-top: 4px;
    margin-left: -17px;
    padding-right: 7px;
    color: #ff3300;
}
.arrow-nav-rev a:before {
    content: "\e079";
    font-family: "Glyphicons Halflings";
    font-size: 11px;
    float: left;
    margin-top: 4px;
    margin-left: -17px;
    padding-right: 7px;
    color: #ff3300;
}

.download-nav a:before,
.download-nav span:before {
    content: "\e025";
    font-family: "Glyphicons Halflings";
    font-size: 17px;
    font-weight: normal;
    float: left;
    margin-top: -2px;
    margin-left: -19px;
    padding-right: 7px;
}
.arrow-back-nav a:before,
.arrow-back-nav span:before {
    content: "\e132";
    font-family: "Glyphicons Halflings";
    font-size: 22px;
    font-weight: normal;
    float: left;
    margin-top: -5px;
    margin-left: 0px;
    padding-right: 7px;
}
.icon-style {
    font-family: "Glyphicons Halflings";
    font-size: 17px;
    font-weight: normal;
    float: left;
    margin-top: -2px;
    margin-left: -19px;
    padding-right: 7px;
}
.location-nav a:before,
.location-nav span:before {
    content: "\e062";
    font-family: "Glyphicons Halflings";
    font-size: 17px;
    font-weight: normal;
    float: left;
    margin-top: -2px;
    margin-left: -19px;
    padding-right: 7px;
}
.mail-nav a:before,
.mail-nav span:before {
    content: "\2709";
    font-family: "Glyphicons Halflings";
    font-size: 17px;
    font-weight: normal;
    float: left;
    margin-top: -2px;
    margin-left: -19px;
    padding-right: 7px;
}
.phone-nav a:before,
.phone-nav span:before {
    content: "\e182";
    font-family: "Glyphicons Halflings";
    font-size: 17px;
    font-weight: normal;
    float: left;
    margin-top: -2px;
    margin-left: -19px;
    padding-right: 7px;
}
.webcast-nav a:before,
.webcast-nav span:before {
    content: "\e038";
    font-family: "Glyphicons Halflings";
    font-size: 17px;
    font-weight: normal;
    float: left;
    margin-top: -2px;
    margin-left: -19px;
    padding-right: 7px;
}
.fax-nav a:before,
.fax-nav span:before {
    content: "\e045";
    font-family: "Glyphicons Halflings";
    font-size: 17px;
    font-weight: normal;
    float: left;
    margin-top: -2px;
    margin-left: -19px;
    padding-right: 7px;
}
.voip-nav a:before,
.voip-nav span:before {
    content: "\e145";
    font-family: "Glyphicons Halflings";
    font-size: 17px;
    font-weight: normal;
    float: left;
    margin-top: -2px;
    margin-left: -19px;
    padding-right: 7px;
}
.list-nav {
    padding-left: 0;
    list-style: none;
    font-weight: bold;
}
.list-nav li {
    position: relative;
    padding-left: 1.2em;
    margin-left: 0;
    padding-bottom: 5px;
}
.list-nav li > a {
    color: #1e1e1e;
}

[class*="col-md-"] .col1,
[class*="col-md-"] .col2 {
    width: 50%;
    float: left;
}
.col-md-4 .col1,
.col-md-4 .col2 {
    width: 100%;
}
.col-md-4 .col2 {
    padding-top: 10px;
}
@media only screen and (max-width: 767px) {
    [class*="col-md-"] .col1,
    [class*="col-md-"] .col2 {
        width: 100%;
        float: left;
    }
    .col-md-4 .col1,
    .col-md-4 .col2 {
        width: 100%;
    }
    .col-md-4 .col2,
    [class*="col-md-"] .col2 {
        padding-top: 10px;
        padding-bottom: 0px;
    }
}
.row-related {
    background-color: #541471;
}
.rl-title {
    padding-bottom: 5px;
    margin-bottom: 10px;
    border-bottom: 1px solid white;
}
.border-slim-black {
    height: 1px;
    background: black;
    margin-bottom: 10px;
}
.richtext-img-left {
    margin-right: 10px;
    float: left;
}
.richtext-img-right {
    margin-left: 10px;
    float: right;
}
.richtext-img-center {
    display: block;
    margin-left: auto;
    margin-right: auto;
}
.related-content ol li {
    padding-bottom: 20px;
    display: inline-block;
    width: 100%;
}
.related-content ol.rl-content {
    -webkit-columns: 2;
    -moz-columns: 2;
    columns: 2;
}
@media screen and (max-width: 767px) {
    .related-content ol.rl-content {
        -webkit-columns: 1;
        -moz-columns: 1;
        columns: 1;
    }
}
figure img {
    padding-bottom: 5px;
}
.container {
    width: 1280px;
}
@media (max-width: 1280px) {
    .container {
        width: auto;
    }
}
.access-filter {
    filter: progid:DXImageTransform.Microsoft.BasicImage(grayscale=1);
    -webkit-filter: grayscale(1);
    filter: grayscale(1);
}
.access-filter .menu-first-section {
    border-bottom: 2px solid #000;
}
.access-filter footer .container {
    border-top: 2px solid #000;
}
.access-filter .carousel img {
    filter: alpha(opacity=30);
    -moz-opacity: 0.3;
    -khtml-opacity: 0.3;
    opacity: 0.3;
}
.access-filter .alert-danger,
.access-filter .alert-info,
.access-filter .alert-warning,
.access-filter fieldset {
    background-color: #fff;
    color: #000;
    border: 1px solid #000 !important;
}
.access-filter legend {
    border: 1px solid #000 !important;
}
.access-filter .btn-primary {
    font-weight: bold;
    background-color: #000;
}
.access-filter .pagination > .active > a,
.access-filter .pagination > .active > a:focus,
.access-filter .pagination > .active > a:hover,
.access-filter .pagination > .active > span,
.access-filter .pagination > .active > span:focus,
.access-filter .pagination > .active > span:hover {
    background-color: #fff;
    font-weight: bold;
}
.panel {
    border: none;
    border-radius: 0;
    -webkit-box-shadow: none;
    box-shadow: none;
    background-color: transparent;
}
.panel .intro strong {
    font-size: 20px;
    color: #1e1e1e;
}
.template.col-md-4 .panel,
.template.col-md-6 .panel,
.template.col-md-8 .panel {
    padding-left: 15px;
    padding-right: 15px;
}
.template.col-md-4 .contact .panel,
.template.col-md-4 .shareprice .panel,
.template.col-md-4 .social-media-feeds .panel,
.template.col-md-6 .contact .panel,
.template.col-md-6 .shareprice .panel,
.template.col-md-6 .social-media-feeds .panel,
.template.col-md-8 .contact .panel,
.template.col-md-8 .shareprice .panel,
.template.col-md-8 .social-media-feeds .panel {
    padding-left: 0px;
    padding-right: 0px;
}
.social-media-feeds .ppan {
    max-height: 400px;
    overflow-y: scroll;
}
.social-media-feeds .feeditem {
    padding-bottom: 10px;
}
.col-sm-4 .panel,
.col-sm-8 .panel,
.col-sm-12 .panel {
    padding: 0 !important;
}
.panel.bar {
    border-top: 5px solid black;
}
.panel-heading {
    padding: 0px 0px 10px 0px;
}
.panel-image {
    margin-bottom: 5px;
}
.panel-image.no-image {
    border-bottom: 1px solid black;
    height: 1px;
}
.panel-body {
    padding: 15px 0px 10px 0px;
}
.panel-body-override {
    padding-top: 0px;
    margin-top: -5px;
}
.panel-body-override p {
    font-size: 1.22em;
}
.panel-list-override {
    padding-top: 0px;
}
blockquote {
    border-left: none;
    padding: 0px;
    margin: 0px;
    font-size: 20px;
}
blockquote p {
    color: white;
}
blockquote footer {
    color: white;
}
blockquote cite {
    font-style: normal;
    font-size: 0.9em;
}
.master-title {
    padding-bottom: 10px;
    border-bottom: 1px solid #000;
    margin-bottom: 20px;
}
.teaser-box .panel-body-override p {
    font-size: 18px;
}
.teaser-box .master-title.no-bar h2 {
    margin-top: 0;
}



.affiliate-links .dropdown-square {
    float: right;
}
.affiliate-links .dropdown-square .btn {
    border-radius: 0px;
}
.affiliate-links .dropdown-square .dropdown-menu {
    border-radius: 0px;
    -webkit-box-shadow: none;
    box-shadow: none;
    padding-right: 30px;
}
.affiliate-links .dropdown-square span {
    padding-left: 30px;
}
@media only screen and (max-width: 767px) {
    .affiliate-links .dropdown-square {
        float: left !important;
    }
}
.col-md-4 .col2 .dropdown-square {
    float: left !important;
}
.searchnews .newssearchbox {
    width: 85%;
    border-radius: 0px;
    float: left;
}
.searchnews .btn {
    right: 0;
    border-radius: 0px;
    width: 40px;
    height: 30px;
}
.newslist .row {
    padding-bottom: 10px;
}
.newslist .display-date {
    padding-right: 10px;
    float: left;
}
@media only screen and (max-width: 767px) {
    .newslist .display-date {
        width: 100%;
        float: left;
    }
}
.newssummary {
    font-style: italic;
    padding-bottom: 20px;
}
.pagination-sm > li:first-child > a,
.pagination-sm > li:first-child > span,
.pagination-sm > li:last-child > a,
.pagination-sm > li:last-child > span {
    border-radius: 0px;
}
.pagination > li > a:focus,
.pagination > li > a:hover,
.pagination > li > span:focus,
.pagination > li > span:hover {
    color: black;
}
.pagination > .active > a,
.pagination > .active > a:focus,
.pagination > .active > a:hover,
.pagination > .active > span,
.pagination > .active > span:focus,
.pagination > .active > span:hover {
    background-color: black;
    border-color: #000;
}
.pagination-sm > li > a,
.pagination-sm > li > span {
    color: black;
}
sup.hugin {
    top: 0.5em;
}
table.hugin {
    font-size: 1em;
    border-top: 2px solid black;
    border-bottom: 2px solid black;
    border-collapse: collapse;
    width: 100%;
    margin-bottom: 20px;
}
table.hugin td {
    border-bottom: 1px solid black;
    border-top: none;
    padding: 0px 20px 0px 4px;
    vertical-align: middle;
}
@media only screen and (max-width: 767px) {
    table.hugin {
        width: auto;
    }
    table.hugin td {
        border-bottom: 1px solid black;
        border-top: none;
        padding: 0px 0px 0px 0px;
        vertical-align: middle;
    }
}
p.hugin {
    margin: 0;
    padding-bottom: 0.675em;
}
h1.hugin {
    text-transform: none;
}
.headcount.panel-body {
    padding: 0px;
}
.headcount.panel-body .row {
    border-bottom: 1px solid black;
    margin-left: 0px;
    margin-right: 0px;
}
.headcount.panel-body .row div {
    padding-left: 0px;
    padding-right: 0px;
}
.headcount.panel-body .row .col-md-6:last-of-type,
.headcount.panel-body .row .col-xs-3:last-of-type {
    text-align: right;
}
.headcount.panel-body .row.employee-total {
    border-bottom: 2px solid black;
}
.headcount.panel-body .row.employee-date {
    border-bottom: none;
    font-size: 10px;
    padding-top: 5px;
}
.internal-contact {
    padding: 0px;
}
.internal-contact .row {
    margin-left: 0px;
    margin-right: 0px;
    padding-bottom: 5px;
}
.internal-contact .row > div {
    padding-left: 0px;
}
.internal-contact .internal-contact-sub {
    padding-top: 5px;
}
.panel-body.shareprice .price {
    font-size: 28px;
}
.panel-body.shareprice .pricediff-up {
    font-size: 22px;
    color: #66cc00;
}
.panel-body.shareprice .pricediff-down {
    font-size: 22px;
    color: #ff3300;
}
.panel-body.shareprice .shareprice-arrow {
    padding-right: 15px;
    font-size: 20px;
}
[class*="col-md-"] .panel-body.shareprice > div {
    width: 50%;
    float: left;
}
[class*="col-md-"] .panel-body.shareprice > ul {
    width: 100%;
    float: left;
}
.col-md-4 .panel-body.shareprice > div,
.col-md-4 .panel-body.shareprice > ul {
    width: 100%;
}
.col-md-4 .panel-body .smaller-padding {
    padding-bottom: 0px !important;
}
@media only screen and (max-width: 480px) {
    [class*="col-md-"] .panel-body.shareprice > div,
    [class*="col-md-"] .panel-body.shareprice > ul {
        width: 100%;
        float: left;
    }
    .smaller-padding {
        padding-bottom: 0px !important;
    }
}
.photo-story h3 {
    margin-top: 0;
    margin-bottom: 5px;
}

.photo-story a {
    padding: 10px 0 0 0;
    display: block;
}

.photo-story span {
    text-transform: none;
}
.photo-story .panel-heading a:focus,
.photo-story .panel-heading a:hover {
    text-decoration: none;
    color: black;
}
.photo-story .accordion-toggle:after {
    content: "\e259";
    font-family: "Glyphicons Halflings";
    display: inline-block;
    padding: 10px;
    vertical-align: middle;
    float: right;
    margin-top: -20px;
    color: #777;
}
.photo-story .accordion-opened .accordion-toggle:after {
    content: "\e260";
}
.photo-story img {
    float: left;
}
@media only screen and (max-width: 480px) {
    .photo-story img {
        float: none;
    }
    .photo-story .col-md-9 {
        padding-top: 20px;
    }
}
.photo-story.row {
    padding-bottom: 20px;
    margin: 0px;
}
@media only screen and (max-width: 480px) {
    .mobile.list-nav {
        float: left;
        padding-top: 20px;
        padding-bottom: 0px;
        clear: both;
    }
}
.col-xs-10.override-padding-lr {
    padding: 0px 5px;
}
.col-md-10.override-padding-lr {
    padding: 0px 5px;
}
.radio-field-label {
    padding-right: 20px;
}
fieldset {
    border: 1px solid #ddd !important;
    margin: 0;
    min-width: 0;
    padding: 10px;
    position: relative;
    border-radius: 4px;
    background-color: #f5f5f5;
    padding-left: 10px !important;
    margin-bottom: 20px !important;
}
legend {
    font-size: 14px;
    font-weight: bold;
    margin-bottom: 5px;
    width: auto;
    border: 1px solid #ddd;
    border-radius: 4px;
    padding: 5px 10px 5px 10px;
    background-color: #ffffff;
}

.navbar.navbar-default {
    background-color: #ffffff;
    border: none;
}
.menu-first-section {
    border-bottom: 2px solid #ff3300;
}
.menu-second-section {
    background-color: #ff3300;
}
.menu-second-section a {
    color: #fff !important;
}
.menu-second-section .navbar-nav {
    float: right;
}
.menu-second-section .dropdown-menu {
    border-top: none;
    border-radius: 0px;
    margin-left: 15px;
    background-color: #ff3300;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
}
.menu-second-section .dropdown-menu li {
    padding-left: 10px;
    padding-right: 10px;
}
.menu-second-section .dropdown-menu li > a {
    padding-top: 10px;
    padding-bottom: 5px;
    padding-left: 10px;
    padding-right: 10px;
}
.menu-second-section .dropdown-menu li:not(:first-child) > a {
    border-top: 1px solid #fff;
}
.menu-second-section .dropdown-menu .activesmalltext {
    font-size: 12px;
}
.menu-second-section .dropdown-menu li:first-child {
    padding-top: 5px;
}
.menu-second-section .dropdown-menu li:last-child {
    padding-bottom: 5px;
}
.menu-second-section .dropdown-menu .dropdown-menu > li > a:focus,
.menu-second-section .dropdown-menu li > a:hover {
    background-color: #fff;
    color: #000 !important;
}
.menu-second-section .dropdown-menu li > a > .sub-menu {
    padding-left: 7px;
    padding-right: 5px;
}
.menu-second-section .dropdown-menu-right {
    right: 15px;
}
.menu-second-section .dropdown:hover .dropdown-menu {
    display: block;
}
.navbar-main-wrapper {
    float: right;
    margin-top: 20px;
}
.navbar.navbar-default .navbar-brand {
    text-indent: -9999px;
}
.nav-search-form {
    width: 100%;
}
.nav-search-field {
    float: left;
    padding-left: 15px;
    height: 30px;
    width: 90%;
}
.home-search-field {
    width: 100%;
    border-top: 0px;
    border-left: 0px;
    border-right: 0px;
    border-bottom: solid 1px;
    border-bottom-color: #1e1e1e;
    outline-style: none;
}
.nav-search-icon {
    float: right;
}
.navbar-nav > li > a {
    color: black;
    font-weight: bold;
}
.navbar.navbar-default .navbar-nav .active > a {
    background-color: transparent;
}
@media screen and (max-width: 992px) and (min-width: 767px) {
    .navbar-nav > li > a {
        padding: 15px 7px;
    }
    .navbar-nav > li:first-of-type > a {
        padding-left: 0px;
    }
    .navbar-nav > li:last-of-type > a {
        padding-right: 0px;
    }
}
.nav-block {
    display: block;
    content: "";
    width: 100%;
    height: 5px;
    margin-top: 2px;
}
.navbar.navbar-default .navbar-nav .active > a:after {
    background: #000;
    display: block;
    content: "";
    width: 100%;
    height: 5px;
    margin-top: 2px;
}
.navbar.navbar-default .navbar-nav a:hover:after {
    background: #d2d3d5;
    display: block;
    content: "";
    width: 100%;
    height: 5px;
    margin-top: 2px;
}
.menu-second-section .navbar-nav .active > a:after {
    background: #fff !important;
    display: block;
    content: "";
    width: 100%;
    height: 5px;
    margin-top: 2px;
}
.menu-second-section .navbar-nav a:after {
    background: transparent !important;
    display: block;
    content: "";
    width: 100%;
    height: 5px;
    margin-top: 2px;
}
.menu-second-section .navbar-nav > li > a {
    padding-top: 10px;
    padding-bottom: 7px;
}
.menu-second-section .navbar-nav a:hover:after {
    background-color: #fff !important;
    background-color: rgba(255, 255, 255, 0.5) !important;
    display: block;
    content: "";
    width: 100%;
    height: 5px;
    margin-top: 2px;
}
.navbar.navbar-default .navbar-nav > .active > a:focus {
    background-color: transparent;
}
.navbar-default .navbar-nav > .open > a,
.navbar-default .navbar-nav > .open > a:focus,
.navbar-default .navbar-nav > .open > a:hover {
    background-color: transparent;
}
.navbar.navbar-default .navbar-nav a {
    color: #000;
}
.navbar.navbar-default .navbar-nav .active > a:hover {
    color: black;
}
@media screen and (max-width: 992px) {
    .nav-side-menu li a,
    .navbar-default .navbar-nav > li a {
        font-size: 15px !important;
    }
}
@media screen and (max-width: 800px) {
    .nav-side-menu li a,
    .navbar-default .navbar-nav > li a {
        font-size: 14px !important;
    }
}
@media screen and (max-width: 767px) {
    .navbar-brand {
        padding: 10px 0px 0px 0px;
    }
    .navbar-header {
        min-height: 98px;
        border-bottom: 2px solid #ff3300;
    }
    .nav-search-field {
        padding-bottom: 5px;
    }
    .p-0 {
        padding: 0px 15px;
    }
    body.active {
        position: fixed;
        overflow: hidden;
    }
    #modal-background {
        display: none;
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: #000;
        opacity: 0.5;
        -webkit-opacity: 0.5;
        -moz-opacity: 0.5;
        filter: alpha(opacity=50);
        z-index: 1000;
    }
    #modal-background.active {
        display: block;
    }
    header {
        min-height: 98px !important;
        max-height: 98px !important;
    }
    .nav > li > a:focus,
    .nav > li > a:hover {
        background-color: transparent;
    }
    .navbar-main-wrapper {
        float: left;
        width: 100%;
        margin-top: 0px;
        background-color: #ff3300;
    }
    .navbar-nav {
        width: 100%;
        margin: 0px;
    }
    .navbar-nav li:not(:last-child) {
        border-bottom: 1px solid white;
    }
    .navbar-collapse {
        padding: 0px;
        border-top: none;
    }
    .navbar-collapse .override-padding-lr {
        padding: 0px;
    }
    .menu-first-section {
        border: none;
    }
    .nav-side-menu {
        height: 100%;
    }
    .nav-side-menu ul li button {
        position: absolute;
        right: 0;
        border: none;
        background: transparent;
        outline: none;
    }
    .nav-side-menu li button.arrow:before,
    .nav-side-menu ul button.arrow:before {
        content: "\e260";
        font-family: "Glyphicons Halflings";
        display: inline-block;
        padding: 10px 12px;
        vertical-align: middle;
        color: white;
    }
    .nav-side-menu li button.arrow.collapsed:before,
    .nav-side-menu ul button.arrow.collapsed:before {
        content: "\e259";
    }
    .nav-side-menu ul li button.arrow.collapsed:hover:before,
    .nav-side-menu ul li button.arrow:hover:before {
        color: white;
    }
    .nav-side-menu ul li.currentpage > a {
        text-decoration: underline;
    }
    .navbar-collapse.collapse.in {
        border-bottom: 3px solid #ff3300;
    }
    .navbar.navbar-default .navbar-nav li {
        width: 100%;
        padding-bottom: 0px;
        min-height: 42px;
    }
    .navbar.navbar-default .navbar-nav li a {
        width: 85%;
        color: white;
        line-height: 21px;
    }
    .navbar.navbar-default .navbar-nav li a:hover:after {
        color: white;
        background-color: white;
    }
    .navbar.navbar-default .navbar-nav li .currentpage {
        text-decoration: underline;
    }
    .navbar.navbar-default .navbar-nav li.active a:hover {
        color: white;
    }
    .navbar.navbar-default .navbar-nav li.active a:hover:after {
        display: block;
        background-color: white;
    }
    .navbar.navbar-default .navbar-nav ul.level-1 {
        background-color: #ff3300;
    }
    .navbar.navbar-default .navbar-nav ul.level-1 li a {
        font-weight: bold;
    }
    .navbar.navbar-default .navbar-nav ul.level-2 {
        background-color: #d90373;
    }
    .navbar.navbar-default .navbar-nav ul.level-2 li a {
        padding-left: 30px;
    }
    .navbar.navbar-default .navbar-nav ul.level-3 {
        background-color: white;
    }
    .navbar.navbar-default .navbar-nav ul.level-3 li a {
        padding-left: 60px;
        font-weight: normal;
        color: black;
    }
    .navbar.navbar-default .navbar-nav ul.level-3 li a:hover:after {
        color: black;
        background-color: black;
    }
    .navbar.navbar-default .navbar-nav ul.level-3 li a:hover {
        color: black;
    }
    .navbar.navbar-default .navbar-nav ul.level-3 li.active a:hover {
        color: black;
    }
    .navbar.navbar-default .navbar-nav ul.level-4 li:first-child {
        border-top: 1px solid black;
    }
    .navbar.navbar-default .navbar-nav ul.level-4 li:last-child {
        border-bottom: 1px solid black;
    }
    .navbar.navbar-default .navbar-nav ul.level-4 li a {
        padding-left: 90px;
    }
    .navbar.navbar-default .navbar-nav ul.level-5 li a {
        padding-left: 110px;
    }
    .navbar.navbar-default .navbar-nav .active > a:after {
        display: none;
    }
    .sub-menu-mobile {
    }
    .sub-menu-mobile a.arrow:before {
        content: "\2013";
        font-size: 10px;
        margin-top: 0;
        padding-right: 4px;
        color: white;
    }
    .sub-menu-mobile li a {
        width: 90%;
    }
    .sub-menu-mobile li > ul > li > ul > li > a {
        padding-left: 90px;
    }
    .navbar-default .navbar-nav > li > a {
        width: 90%;
    }
    .collapse.in {
        margin-bottom: 0px;
        padding-bottom: 0px;
    }
    .nav-search-icon {
        margin-top: 40px;
        float: right;
        margin-right: -5px;
    }
    .navbar-header .navbar-toggle {
        border: none;
        background: transparent !important;
        margin-top: 30px;
    }
    .navbar-toggle:hover {
        background: transparent !important;
    }
    .navbar-toggle .icon-bar {
        width: 22px;
        -webkit-transition: all 0.2s;
        -o-transition: all 0.2s;
        transition: all 0.2s;
    }
    .navbar-toggle .top-bar {
        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        transform: rotate(45deg);
        -webkit-transform-origin: 10% 10%;
        -ms-transform-origin: 10% 10%;
        transform-origin: 10% 10%;
    }
    .navbar-toggle .middle-bar {
        opacity: 0;
    }
    .navbar-toggle .bottom-bar {
        -webkit-transform: rotate(-45deg);
        -ms-transform: rotate(-45deg);
        transform: rotate(-45deg);
        -webkit-transform-origin: 10% 90%;
        -ms-transform-origin: 10% 90%;
        transform-origin: 10% 90%;
    }
    .navbar-toggle.collapsed .top-bar {
        -webkit-transform: rotate(0);
        -ms-transform: rotate(0);
        transform: rotate(0);
    }
    .navbar-toggle.collapsed .middle-bar {
        opacity: 1;
    }
    .navbar-toggle.collapsed .bottom-bar {
        -webkit-transform: rotate(0);
        -ms-transform: rotate(0);
        transform: rotate(0);
    }
}
.table-responsive {
    width: 100%;
    border: none;
}
/*@media screen and (max-width: 767px) {
    font-size:  0.8em;
}*/
.iw_component img.overlay {
    height: 100% !important;
}
.no-dcr {
    color: #cc0000;
    font-weight: bold;
    min-height: 50px;
    display: block;
    padding-top: 15px;
}
.media-body a {
    font-weight: normal;
}
.media-body a.anchor-time {
    font-size: 0.8em;
}
.post-body.body-twitter a {
    font-weight: normal;
}
img.feed-logo {
    border: 1px solid #ddd;
}
span.bold-blue {
    color: #0093dd;
    font-weight: bold;
}
span.bold-orange {
    color: #ff3300;
    font-weight: bold;
}
span.bold-pink {
    color: #d90373;
    font-weight: bold;
}
span.bold-purple {
    color: #541471;
    font-weight: bold;
}
div.gsc-adBlock {
    display: none;
}
div.gcsc-branding {
    display: none;
}
div.gsc-result-info {
    display: none;
}
div.gs-webResult.gs-result div.gs-title a.gs-title b {
    color: #ff3300;
}
div.gs-bidi-start-align.gs-snippet b {
    color: #ff3300;
}
a.gs-image img.gs-image {
    border: none;
}
@media print {
    .carousel .carousel-caption h2 {
        font-size: 35px !important;
    }
    .carousel .carousel-caption h2 p {
        font-size: 25px !important;
    }
    .carousel .carousel-caption > div > p {
        font-size: 15px !important;
    }
    a[href]:after {
        content: " ";
    }
}
