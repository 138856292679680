@use '../variables' as *;

.social-media-feeds {
    .idorsia-icon-twitter-blue {
        color: #1da1f2;
    }

    .idorsia-icon-facebook-blue {
        color: #29487d;
    }
}

.social-media-feed {
    min-height: rem(400px);

    .col-md-1 {
        padding: 0;
    }

    .fa-icon {
        font-size: rem(25px);
    }

    .idorsia-icon-facebook {
        color: #3b5998;
    }

    .idorsia-icon-twitter {
        color: #00aced;
    }

    .idorsia-icon-linkedin {
        color: #007bb6;
    }

    .idorsia-icon-youtube {
        color: #b31217;
    }

    .feeder:last-of-type {
        padding-right: 0;
    }

    .feeder-icon {
        float: left;
        margin-left: rem(-20px);
        padding-top: rem(10px);
        width: rem(30px);
    }

    .feeder-feed {
        padding-left: rem(20px);
    }
}

.social-media-sharing {
    margin-bottom: rem(15px);

    h5 {
        margin-top: 0;
    }
}

/* ==========================================================================
   Responsive
   ========================================================================== */

@media screen and (max-width: 767px) {
    .social-media-feed {
        padding-top: rem(15px);
    }

    .social-media-sharing {
        padding-top: rem(15px);
    }
}
