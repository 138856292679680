@use '../variables' as *;

.tabs-section {
  align-items: center;
  display: flex;
  padding: rem(20px) 0;
  position: relative;

  &.has-background {
    min-height: rem(480px);
  }

  & > .container {
    background-color: $white;
    box-shadow: 0 0 12px 4px rgba(0, 0, 0, 0.15);
    max-width: rem(1250px);
    padding: rem(20px);
    position: relative;
    width: 100%;
    z-index: 2;

    /* Nav Tabs */
    .nav-tabs {
        border-bottom: 0;
        margin-bottom: rem(20px);
        padding-left: rem(5px);
        position: relative;
        width: 100%;

        .nav-item {
            margin: 0 rem(10px) rem(10px);

            .nav-link {
                background: none;
                border: 0;
                border-radius: 0;
                bottom: 0;
                display: inline-block;
                font-size: 1em;
                left: 0;
                margin: 0;
                padding: rem(5px);
                position: relative;
                right: 12em;
                text-align: center;
                top: 0;
                /*transition*/
                -webkit-transition: width 0.3s,right 0.3s;
                -moz-transition: width 0.3s,right 0.3s;
                -o-transition: width 0.3s,right 0.3s;
                transition: width 0.3s,right 0.3s;

                &:focus {
                    color: $black;
                }

                &::after {
                  background: $black;
                  bottom: 0;
                  content: '';
                  height: rem(2px);
                  pointer-events: none;
                  position: absolute;
                  right: 0;
                  /*transition*/
                  -webkit-transition: all 0.5s ease;
                  -moz-transition: all 0.5s ease;
                  -o-transition: all 0.5s ease;
                  transition: all 0.5s ease;
                  width: 100%;
              }
            }
        }

        .border {
          background: transparent;
          bottom: rem(6px);
          content: '';
          height: rem(6px);
          opacity: 1;
          pointer-events: none;
          position: absolute;
          right: 0;
          /*transition*/
          -webkit-transition: all 0.5s ease;
          -moz-transition: all 0.5s ease;
          -o-transition: all 0.5s ease;
          transition: all 0.5s ease;
          visibility: visible;
          width: 0;
        }

        // In case the Tabs are displayed on more than 2 lines
        &.normal-style {
          .nav-link.active {
            &::after {
              bottom: rem(-6px);
              height: rem(8px);
            }
          }

          .border {
            opacity: 0;
            visibility: hidden;
          }
        }
    }

    /* Content */
    .tab-content {
      margin: 0 auto;
      max-width: rem(1280px);
      width: 100%;

      .tab-pane {
          padding: rem(15px);

          &.active {
              animation-duration: 0.75s;
              animation-name: fadeInLeft;
          }
      }
    }
  }
}

/* Colors Theme */
@each $theme, $color in $themes {
  .theme-#{$theme} {
    .tabs-section {
      .container {
        .nav-tabs {
          .border {
              background-color: $color;
          }
        }
      }
    }
  }
}

@each $theme, $color in $themes {
  .theme-#{$theme} {
    .tabs-section {
      .container {
        .nav-tabs {
          .nav-item {
            .nav-link.active {
              &::after {
                background-color: $color;
              }
            }
          }
        }
      }
    }
  }
}

/* Adjust padding when inside a layout */
.row.template {
  .nav-tabs {
    margin-left: rem(-10px);
    padding-left: 0;
  }

  .tab-content {
    .tab-pane {
      padding: rem(15px) 0;
    }
  }
}

/* ==========================================================================
+   Responsive
+   ========================================================================== */

@media screen and (min-width: 768px) {
  .tabs-section {
    padding: rem(15px);

    & > .container {
      .nav-tabs {
        .nav-item {
          .nav-link {
            font-size: 1.3em;
          }
        }

        .border {
          bottom: rem(4px);
          height: rem(8px);
        }
      }
    }
  }
}

/* ==========================================================================
+   Animation
+   ========================================================================== */

  @keyframes fadeInLeft {
    from {
      opacity: 0;
      transform: translate3d(rem(-20px), 0, 0);
    }

    to {
      opacity: 1;
      transform: translate3d(0, 0, 0);
    }
  }
