@use '../variables' as *;

#locations-module {
  position: relative;
  width: 100%;
}

.locations-intro {
  font-weight: 500;
  padding-left: 2em;
  padding-bottom: 1.25em;
}

@media (max-width: 819px) {

  .locations-intro {
    font-size: 14px;
    padding-left: 0;
    text-align: center;
  }
}

#locations-map {
  position: relative;
}

.location-box {
  font-size: 16px;
  position: absolute;
  top: 50%;
  left: 50%;
  width: calc(100% - 15px);
  max-width: 580px;
  transform: translate(-50%, -50%);
  background: white;
  box-shadow: 0 0 10px rgba(0,0,0,0.1);
  overflow: hidden;
}

.location-box__header > h2 {
  font-size: 1.675em;
  font-weight: lighter;
  margin: -0.25em 0;
  line-height: 1.25em;
}


@keyframes fill-border {
  from {
    width: 0;
  }
  to {
    width: 100%;
  }
}

.location-box__header {
  position: relative;
  display: block;
}

.location-box__header::after {
  content: "";
  position: absolute;
  left: 0;
  top: 100%;
  width: 0;
  height: 4px;
  background: #000;
}

@media (max-width: 819px) {
  .location-box__header::after {
    height: 3px;
  }
}

.location-box.js-visible .location-box__header::after {
  width: 100%;
  animation: fill-border 1s forwards 0.15s;
}

.location-box > * {
  padding: 2em 4em 2em 2em;
}

.location-box__content {
  display: grid;
  grid-template-columns: 1fr 1fr; /* Two equal columns */
  column-gap: 2em; /* 2em gap between columns */
  padding-bottom: 0;
}

.location-box__content strong {
  font-weight: 500;
}

@media (max-width: 819px) {
  .location-box > * {
    padding: 1em;
  }

  .location-box__content {
    column-gap: 1em;
  }
}

.location-box__content > * {
  flex: 0 1 50%;
}

.location-box__content > *,
.location-box__footer > * {
  font-size: 0.8125em;
}

.location-box__close {
  position: absolute;
  top: 0;
  right: 0;
  width: 5.25em;
  height: 100%;
  border: 0;
  background: transparent;
  -webkit-appearance: none;
  padding: 1em;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media (max-width: 819px) {
  .location-box__close {
    width: 3em;
    height: 100%;
  }
}

.location-marker {
  position: absolute;
  top: 0;
  right: 0;
  width: 1em;
  height: 1.5em;
  border: 0;
  -webkit-appearance: none;
  cursor: pointer;
  background-size: auto 100%;
  background-color: transparent;
  display: inline-block;
}

@media (min-width: 820px) {
  .location-marker::before {
    content: "";
    background-color: rgba(0, 0, 0, 0.1);
    border-radius: 50%;
    width: 2.5em;
    height: 2.5em;
    display: inline-block;
    margin: -0.75em -1.25em;
    pointer-events: none;
  }
}

.location-box .arrow-nav a::before {
  font-size: 0.75em;
  margin-left: -1.0625em;
  margin-top: 0.1875em;
  padding-right: 0.625em;
}



.locations-selector {
  display: flex;
  justify-content: space-between;
  margin: 0 -15px 30px;
  padding: 0 10px;
}

@media (max-width: 819px) {
  .locations-selector {
    margin-bottom: 10px;
  }
}

.location-selector-arrow {
  flex-grow: 0;
  width: 48px;
  height: 48px;
  background: none;
  border: 0;
}

.location-selector-arrow[disabled] {
  color: #dddddd
}

.locations-selector-labels {
  position: relative;
  flex-grow: 1;
  height: 48px;
  font-size: 18px;
}

.locations-selector-label {
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  font-weight: 500;
  transform: translateY(100%);
  opacity: 0;
  text-align: center;
  transition: transform 0s 0.75s, opacity 0.5s;
}

.locations-selector-label.js-visible {
  transform: translateY(-50%);
  opacity: 1;
  transition: transform 0.5s 0s, opacity 0.5s;
}

button.locations-selector-arrow {
  color: #f30;
  content: "";
  float: left;
  font-family: "Glyphicons Halflings", sans;
  font-size: .75rem;
  margin-left: -1.0625rem;
  margin-top: .1875rem;
  padding-right: .625rem;
}

.locations-selector-label-area {
  position: absolute;
  top: 50%;
  width: 100%;
  text-align: center;
  transform: translateY(100%);
  transition: transform 0s 0.5s, opacity 0.25s;
}

.locations-selector-label-area.js-visible {
  transform: translateY(-50%);
  transition: transform 0.5s 0s, opacity 0.25s;
}

button.locations-selectors-arrow {
  color: #f30;
  content: "";
  float: left;
  font-family: "Glyphicons Halflings", sans;
  font-size: .75rem;
  margin-left: -1.0625rem;
  margin-top: .1875rem;
  padding-right: .625rem;
}

#location-map-wrapper {
  position: relative;
  width: 100%;
  aspect-ratio: 1/1.1;
}

@media (min-width: 820px) {
  #location-map-wrapper {
    aspect-ratio: 1280/630;
  }
}

.location-map {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-position: center center;
  background-repeat: no-repeat;
}

.location-map-mobile {
  visibility: hidden;
}

@media (min-width: 820px) {
  .location-map.hidden-desktop {
    display: none;
  }

  .hidden-desktop {
    display: none;
  }
}

@media (max-width: 819px) {
  .location-map.hidden-mobile {
    display: none;
  }

  .hidden-mobile {
    display: none;
  }
}

.location-map.world {
  background-size: 138% auto;
}

.location-map.northAmerica {
  background-size: 700% auto;
  background-position: 20% 39%;
}

.location-map.europe {
  background-size: 700% auto;
  background-position: 49% 28%;
}

.location-map.asia {
  background-size: 700% auto;
  background-position: 85.5% 39%;
}

.location-map.js-visible {
  visibility: visible;
}

#location-backdrop {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  background-color: rgba(0, 0, 0, 0.1);
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.25s, visibility 0s 0.3s;
}

#location-backdrop.js-visible {
  opacity: 1;
  visibility: visible;
  transition: opacity 0.5s 0.2s, visibility 0s;
}

.location-marker {
  background-position: center center;
  background-repeat: no-repeat;
  margin-top: -1.5em;
  opacity: 0;
}

@media (min-width: 820px) {
  .location-marker > span {
    opacity: 0;
    visibility: hidden;
    transition: visibility 0s 0.65s, opacity 0.5s;
  }

  .location-marker.js-active > span,
  .location-marker:hover > span {
    opacity: 1;
    visibility: visible;
    transition: visibility 0s 0s, opacity 0.5s 0.25s;
  }


  .location-marker span {
    position: absolute;
    top: 50%;
    left: auto;
    right: 30px;
    text-align: center;
    font-size: 14px;
    line-height: 1.15em;
  }

  .location-marker .top {
    top: auto;
    bottom: 100%;
    right: 30px;
    left: 50%;
    margin-bottom: 10px;
    width: 300px;
    transform: translateX(-50%);
    text-align: center;
  }

  .location-marker .bottom {
    top: 100%;
    bottom: auto;
    right: 30px;
    left: 50%;
    margin-top: 10px;
    width: 300px;
    transform: translateX(-50%);
    text-align: center;
  }

  .location-marker .right {
    top: 50%;
    left: 30px;
    right: auto;
    width: 300px;
    transform: translateY(-50%);
    text-align: left;
  }

  .location-marker .left {
    top: 50%;
    left: auto;
    right: 30px;
    width: 300px;
    transform: translateY(-50%);
    text-align: right;
  }
}

@media (max-width: 819px) {
  .location-marker span {
    position: absolute;
    top: 50%;
    left: auto;
    right: 30px;
    line-height: 1.15em;
    font-size: 14px;
    width: 120px;
    text-align: center;
  }

  .location-marker .top {
    top: auto;
    bottom: 100%;
    margin-bottom: 6px;
    left: 50%;
    width: 100px;
    margin-left: -50px;
  }

  .location-marker .bottom {
    top: 100%;
    bottom: auto;
    margin-top: 6px;
    left: 50%;
    width: 100px;
    margin-left: -50px;
  }

  .location-marker .right {
    top: 50%;
    left: 22px;
    right: auto;
    transform: translateY(-50%);
    text-align: left;
  }

  .location-marker .left {
    top: 50%;
    left: auto;
    right: 22px;
    transform: translateY(-50%);
    text-align: right;
  }
}

@keyframes fadeIn {
  0% { opacity: 0; }
  100% { opacity: 1; }
}

.top-nav-submenu.d-block .location-marker {
  opacity: 1;
  animation: fadeIn 1.5s forwards ease-in-out;
}

.location-box {
  opacity: 0;
  visibility: hidden;
  transform: translate(-50%, 0%);
  transition: opacity 0.3s, visibility 0s 1s, transform 0.3s;
  z-index: 2;
}

.slideLeft {
  opacity: 0;
  transform: translateX(-50px);
  transition: transform 0.25s 0.5s ease-in-out, opacity 0.25s 0s ease-in-out;
}

.js-visible .slideLeft {
  opacity: 1;
  transform: translateX(0px);
  transition: transform 0.75s 0.25s ease-in-out, opacity 0.75s 0.25s ease-in-out;
}

.slideUp {
  opacity: 0;
  transform: translateY(25px);
  transition: transform 0s 0.5s ease-in-out, opacity 0s 0s ease-in-out;
}

.js-visible .slideUp {
  opacity: 1;
  transform: translateY(0px);
  transition: transform 0.75s 0.25s ease-in-out, opacity 0.75s 0.25s ease-in-out;
}

.fadeIn {
  opacity: 0;
  transition: opacity 0.25s 0s ease-in-out;
}

.js-visible .fadeIn {
  opacity: 1;
  transition: opacity 0.5s 0.25s ease-in-out;
}

.location-box.js-visible {
  opacity: 1;
  visibility: visible;
  transform: translate(-50%, -50%);
  transition: opacity 0.5s 0.2s, visibility 0s, transform 0.5s 0.2s;
}

.location-marker::after {
  content: attr(data-location);
  position: absolute;
  left: -3em;
  right: -3em;
  top: 100%;
  margin-top: 1em;
  font-size: 0.8em;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.25s, transform 0s 0.25s, visibility 0s 0.5s;
}

.location-marker.marker-top::after {
  top: auto;
  bottom: 100%;
  margin-bottom: 1em;
}

.location-marker.marker-left::after {
  left: auto;
  right: 3em;
  top: 50%;
  margin-top: -1em;
  text-align: right;
}

.location-marker.marker-right::after {
  left: 3em;
  right: auto;
  top: 50%;
  margin-top: -1em;
  text-align: left;
}

.location-marker:hover::after,
.location-marker:focus::after,
.location-marker.js-active::after{
  transform: translateY(0);
  opacity: 1;
  visibility: visible;
  transition: opacity 0.75s 0.15s, transform 0.75s 0.15s, visibility 0s 0s;
}


@keyframes scaleLoop {
  0% { transform: scale(0.9); }
  90% { transform: scale(1); }
  100% { transform: scale(0.9); }
}

.location-marker::before {
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.25s, transform 0s 0.25s, visibility 0s 0.5s;
  animation: scaleLoop 2s infinite ease-in-out;
}

.location-marker:hover::before,
.location-marker:focus::before,
.location-marker.js-active::before{
  opacity: 1;
  visibility: visible;
  transition: opacity 0.75s 0.15s, transform 0.75s 0.15s, visibility 0s 0s;
}


#header .top-nav-submenu__content__main-section {
  flex: 0 0 100%;
  min-width: 100%;
}

#header .top-nav-submenu__content__icon-section {
  display: none;
}

.location-box__header > h2 {
  font-size: 1em;
}

@media (min-width: 1181px) {
  #header .top-nav-submenu__content__main-section {
    flex: 0 0 85%;
    min-width: 85%;
  }

  #header .top-nav-submenu__content__icon-section {
    flex: 0 0 15%;
    min-width: 15%;
    display: inline-flex;
    justify-content: flex-end;
  }

  .location-box__header > h2 {
    font-size: 1.675em;
  }
}
