@use '../variables' as *;

.introduction-text {
    .content {
        display: flex;
        flex-direction: column;
    }

    &.img-left {
        .content {
          align-items: flex-start;
          display: flex;
          flex-direction: column;

            .text {
                order: 2;
            }

            img {
                margin: rem(10px) 0 0;
                order: 1;
            }
        }
    }
}



/* ==========================================================================
+   Responsive
+   ========================================================================== */

@media (min-width: 600px) {
    .introduction-text {
        .content {
            flex-direction: row;
            align-items: start;

            img {
                margin: rem(10px) 0 0 rem(40px);
                max-width: 40%;
                object-fit: contain;
            }

          .text {
            align-self: flex-start;
          }
        }

        &.img-left {
            .content {
              flex-direction: row;
              justify-content: flex-start;

                img {
                    margin: rem(10px) rem(40px) 0 0;
                }
            }
        }
    }
}
