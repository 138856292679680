@use '../variables' as *;

// NOTE: old syling from current website refacored.
.media-release-list {
  .tab-content {
    margin-bottom: rem(15px);
  }

  #tabs {
    border-bottom: rem(1px) solid $light-grey-200;

    ul {
      display: table;
      list-style-type: none;
      margin: 0;

      & > li {
        border: rem(1px) solid transparent;
        border-radius: rem(4px) rem(4px) 0 0;
        float: left;
        margin-bottom: rem(-1px);
        margin-left: rem(2px);
        padding: rem(15px);

        &.active {
          background-color: $white;
          border: rem(1px) solid $light-grey-200;
          border-bottom-color: transparent;
          color: $orange;
          cursor: default;

          a {
            color: $orange;
            cursor: default;
            font-weight: 700;
            text-decoration: none;
          }
        }

        &:hover {
          background-color: $light-grey;
        }

        a {
          line-height: 1.5em;
          margin-right: rem(2px);
          text-decoration: none;
        }
      }
    }
  }

  .newslist .display-date {
    flex: 0 0 rem(120px);
  }

  .arrow-nav {
    padding-left: rem(20px);
    position: relative;

    &::before {
      color: #ff3300;
      content: '\e080';
      font-family: 'Glyphicons Halflings';
      font-size: rem(11px);
      left: 0;
      margin-top: rem(4px);
      position: absolute;
    }

    a {
      &::before {
        display: none;
      }
    }
  }
}

.media-release-detail {
  padding: rem(20px) 0;
}

.searchnews .btn-red-primary {
  height: rem(34px);
  padding: 0;
}

.media-release-list {
  margin-top: rem(20px);
}

/* ==========================================================================
   Responsive
   ========================================================================== */

   @media (min-width: 768px) {
    .media-release-detail {
      padding: rem(20px) rem(15px);
    }
   }
