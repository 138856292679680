@use '../variables' as *;

.process-video {
    height: auto !important;
    margin-bottom: rem(20px);
    margin-top: rem(20px);
    max-width: rem(640px);
    overflow-y: hidden;
    padding-bottom: rem(15px);
    position: relative;
    width: 100%;

    &__image,
    &__play {
        cursor: pointer;
        left: 50%;
        opacity: 0;
        position: absolute;
        top: 50%;
        transform: translate(-50%, -50%);
        transition: all 0.3s ease-in;
        visibility: hidden;
        z-index: 9;

        &.show {
            opacity: 1;
            visibility: visible;
        }
    }

    &__play {
        width: 20%;
        z-index: 99;
    }

    &__iframe {
        position: relative;
    }
}

.hide {
    display: none;
}

/* Video in Modal */
.modal-dialog {
    .embed-responsive {
        position: absolute;
        top: 0;
        width: 100%;
    }
}

/* ==========================================================================
+   Responsive
+   ========================================================================== */

@media screen and (min-width: 992px) {
    .process-video {
        padding-bottom: 0;
    }

    .col-md-12 .process-video,
    .tabs-section .process-video {
        max-width: rem(640px);
    }
}
