@use '../variables' as *;

#searchApplication {
    padding-top: rem(20px);
}

#searchApplication input {
    background-color: #ffffff;
    background-image: none;
    border: rem(1px) solid #cccccc;
    border-radius: rem(4px) 0 0 rem(4px);
    border-right: 0;
    color: #555555;
    display: block;
    float: left;
    font-size: rem(14px);
    height: rem(34px);
    line-height: 1.42857143;
    padding: rem(6px) rem(12px);
    width: 95%;
}

#searchApplication button {
    background-color: #337ab7;
    background-image: none;
    border: rem(1px) solid transparent;
    border-color: #2e6da4;
    border-radius: 0 rem(4px) rem(4px) 0;
    color: #ffffff;
    cursor: pointer;
    display: inline-block;
    float: left;
    font-size: rem(14px);
    font-weight: normal;
    line-height: 1.42857143;
    margin-bottom: 0;
    padding: rem(6px) rem(12px);
    text-align: center;
    touch-action: manipulation;
    touch-action: manipulation;
    user-select: none;
    user-select: none;
    user-select: none;
    user-select: none;
    vertical-align: middle;
    white-space: nowrap;
}

#searchApplication button:hover {
    background-color: #286090;
    border-color: #204d74;
    color: #ffffff;
}

#searchApplication #sortby {
    margin-top: rem(60px);
    padding-right: rem(15px);
}

#searchApplication .sortby-item .ng-binding:hover {
    color: #777777;
}

#searchApplication .sortby-item .ng-binding {
    float: right;
    margin-left: rem(15px);
}
@media only screen and (max-width: 767px) {
    #searchApplication input {
        width: auto;
    }
}

#results header {
    height: auto;
    /*max-height: auto;*/
    min-height: auto;
}
