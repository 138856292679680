@use '../variables' as *;

.toast {
    backdrop-filter: blur(10px);
    background-clip: padding-box;
    background-color: $white;
    border: rem(1px) solid $border;
    border-radius: rem(5px);
    bottom: 2rem;
    box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, 0.1);
    opacity: 0;
    overflow: hidden;
    padding: 1rem;
    position: fixed;
    right: 2rem;

    &.show {
      opacity: 1;
    }

    &.hide {
      opacity: 0;
    }
  }
