@use '../variables' as *;

@media (min-width: 768px) {

    // MAGAWS-50 : Adjust padding on labels only on Subscribe form
    #stayinformed,
    #subscribe,
    #unsubscribe,
    #passwordretrieval {
      &.form-horizontal {
        .control-label {
          padding-top: rem(4px);
        }
      }

      fieldset {
        background-color: $white;
      }
    }

}
