@use '../variables' as *;

/* Print style only */

// Size Guide
// Pixels => Points
//   10px => 8pt
//   11px => 8pt
//   12px => 9pt
//   13px => 10pt
// 14px => 11pt
//   15px => 11pt
//   16px => 12pt
//   17px => 13pt
// 18px => 14pt
//   19px => 14pt
//   20px => 15pt
// 21px => 16pt
// 22px => 17pt
// 23px => 17pt
// 24px => 18pt

// Relative sizes:
// 'xs': '0.75rem',    // 13.5px
// 'sm': '0.888rem',   // 16px
// 'base': '1rem',     // 18px
// 'lg': '1.167rem',   // 21px
// 'xl': '1.278rem',   // 23px
// '2xl': '1.5rem',    // h4 27px
// '3xl': '1.875rem',  // 33px
// '4xl': '2.25rem',   // h3 40px
// '5xl': '3rem',      // h2 53px
// '6xl': '4rem',      // h1 67px

@media print {
  //  Elements not needed on print
  #get-away,
  .btn-panic,
  .share-page,
  .map,
  .footer-menu,
  .footer-bottom {
    display: none;
  }

  .topnav-extra,
  .topnav-mainmenu {
    display: none !important;
  }

  //  Set content width, remove floats and margins
  //  Attention: The classes and IDs vary update classes here
  body,
  #main {
    float: none;
    margin-bottom: 2em;
    margin-top: 2em;
    width: 100%;
  }

  * {
    color: $black !important;
  }

  //  Set font to 18px/14pt, set background to white and font to black.
  //  This saves ink
  html,
  body {
    background: $white !important;
    color: $black;
    font-family: $font-stack-default;
    //  Opt could make serif font for printing if easier to read on paper?
    //  font-family: Merriweather, Cambria, 'Times New Roman', Times, serif;
    font-size: 14pt;
    line-height: 1.25;
  }

  //  Remove background from solid boxes
  //  This saves ink
  .about-us > div:nth-child(2),
  .accordion-tab__label,
  .callout-horizontal__content,
  .hero,
  .transition-heading,
  .transition-overlap {
    background: $white !important;
  }

  .callout-horizontal,
  .callout-card,
  .callout-horizontal-two,
  .carousel-slide,
  .cta,
  .download-button,
  .events-listing__recurring,
  .news-alert,
  .page-hero,
  .page-pagination__options__item.active,
  .resource-hotline,
  .resource-hotline .rounded-full,
  .section-hero {
    background: $white !important;
    border: rem(1px) solid #5c5c5c;
  }

  th {
    background: #bdbdbd !important;
    border: rem(1px) solid #5c5c5c;
  }

  tr td:first-of-type,
  td.col-first {
    background: $grey !important;
  }

  .homepage-hero__description,
  .page-hero__description,
  .section-hero__description {
    margin-bottom: 1rem;
    margin-top: 1rem;
  }

  .page-pagination {
    text-align: left;
  }

  .page-pagination__options__item.active {
    padding-left: 1.2rem;
  }

  // open all accordion
  .accordion-tab__content {
    display: block;
  }

  // * Adjust paper margins
  @page {
    margin: 2cm
  }

  h1 {
    //  Reduce from font-size: 67pt;
    font-size: 32pt;
    margin-top: rem(25px);
  }

  h2 {
    //  Reduce from font-size: 53pt;
    font-size: 32pt;
    margin-top: rem(25px);
  }

  h3 {
    //  Reduce from font-size: 40pt;
    font-size: 20pt;
    margin-top: rem(25px);
  }

  h4 {
    //  Reduce from font-size: 27pt;
    font-size: 18pt;
    margin-top: rem(25px);
  }

  h5 {
    //  Reduce from font-size: 23pt;
    font-size: 16pt;
    margin-top: rem(25px);
  }

  h6 {
    //  font-size: 18pt;
    font-size: 14pt;
    margin-top: rem(25px);
  }

  //  Define all page breaks
  a {
    page-break-inside: avoid;
  }

  blockquote {
    page-break-inside: avoid;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    page-break-after: avoid;
    page-break-inside: avoid
  }

  img {
    page-break-after: avoid;
    page-break-inside: avoid;
  }

  table,
  pre {
    page-break-inside: avoid
  }

  table,
  .heading,
  ul,
  ol,
  dl {
    page-break-before: avoid
  }

  .download-button {
    page-break-inside: avoid
  }

  //  Show link color and link behavior
  a:link,
  a:visited,
  a {
    background: transparent;
    color: $black; //  TODO : test and which colour, or just inside body
    font-weight: bold;
    text-align: left;
    text-decoration: underline;
  }

  a {
    page-break-inside: avoid;
  }

  //  Add link url to the page
  a[href^=http]::after {
    content: ' <' attr(href) '> ';
  }

  a::after > img {
    content: '';
  }

  article a[href^='#']::after {
    content: '';
  }

  .breadcrumb li a::after {
    content: '';
  }

  //  Add in-site link url to the page
  a:not(:local-link)::after {
    content: ' <' attr(href) '> ';
  }

  /**
  * Make embedded videos disappear and reduce the iframes whitespace to zero.
  */
  .iframe-container,
  ins {
    display: none;
    height: 0 !important;
    line-height: 0pt !important;
    overflow: hidden !important;
    white-space: nowrap;
    width: 0 !important;
  }

  .embed-youtube,
  .embed-responsive {
    height: 0;
    overflow: hidden;
    position: absolute;
  }

  //  Elements that need change spacing

  #newShareBottomPage {
    padding: 0 1.5cm;
  }

  //  Insert custom messages before and after content
  header::before {
    color: #999999 !important;
    content: '\ All Rights Reserved (c) Maricopa County';
    font-size: 1em;
    padding-top: rem(30px);
    text-align: center !important;
  }

  footer::before {
    color: #5c5c5c !important;
    content: '\ Thank you very much for printing our page. We hope that information from us can be helpful.';
    font-size: 1em;
    padding-top: rem(30px);
    text-align: center !important;
  }

  //  Define important elements
  p,
  address,
  li,
  dt,
  dd,
  blockquote {
    font-size: 100%
  }

  //  Code check
  code,
  pre {
    font-family: 'Courier New', Courier, mono;
  }

  ul,
  ol {
    list-style: square;
    margin-bottom: 20pt;
    margin-left: 18pt;
  }

  li {
    line-height: 1.6em;
  }
}
