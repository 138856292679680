@use '../variables' as *;

.new-progress-bar {
    background-color: $white;
    border-bottom: 0;
    border-radius: 0;
    box-shadow: 0 0 rem(12px) rem(6px) rgba(0, 0, 0, 0.15);
    display: none;
    min-height: rem(40px);
    transition: all 0.6s cubic-bezier(0.25, 0.8, 0.25, 1);
    z-index: 18;

    &.fixed-bar-up {
      left: 0;
      position: fixed;
      right: 0;
    }

    .container {
        padding-left: 0;
    }

    .progress-bar-nav {
            display: flex;
            flex-wrap: wrap;
            margin: 0;
            overflow: hidden;
            padding: 0;
            position: relative;
            width: 100%;

        li {
            border: none;
            margin: 0;
            max-width: 35%;
            padding: 0;
            position: relative;
            text-align: center;

            .nav-link {
                border-bottom: rem(4px) solid $white;
                border-left: rem(4px) solid $white;
                border-top: rem(4px) solid $white;
                height: 100%;
                line-height: rem(38px);
                padding: 0 rem(5px) 0 rem(25px);
                position: relative;
                width: 100%;

                span {
                    display: block;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                }

                .first-arrow,
                .first-arrow::before,
                &::before,
                &::after {
                    border-color: transparent transparent transparent $white;
                    border-style: solid;
                    content: '';
                    height: 0;
                    left: 100%;
                    position: absolute;
                    width: 0;
                    z-index: 1;
                }

                .first-arrow::before,
                &::before {
                    border-width: rem(19px);
                    display: block;
                    top: 0;
                    z-index: 2;
                }

                &::before {
                    top: rem(-1px);
                }

                &::after {
                    border-width: rem(24px);
                    top: rem(-6px);
                }

                &:hover {
                    background: $white;
                }

                .first-arrow {
                    border-width: rem(24px);
                    left: rem(-4px);
                    opacity: 0;
                    top: rem(-6px);

                    &::before {
                        left: rem(-25px);
                        top: rem(-19px);
                    }
                }

                &.active {
                    border-bottom-color: $red;
                    border-left-color: $red;
                    border-top-color: $red;

                    .first-arrow,
                    &::after {
                        border-color: transparent transparent transparent $red;
                        opacity: 1;
                    }

                    &::after {
                        border-width: rem(24px);
                        top: rem(-6px);
                    }
                }

                &:focus {
                    background-color: $white;
                }
            }

            &:first-child {
                .nav-link {
                    border-left: rem(4px) solid $white;
                    padding-left: rem(15px);

                    &.active,
                    &:hover {
                        border-left: rem(4px) solid $red;
                    }
                }
            }
        }
    }

    .navbar-toggle {
        .idorsia-icon-bar {
            background-color: $black;
        }
    }
}

/* ==========================================================================
   Responsive
   ========================================================================== */

@media screen and (min-width: 768px) {
    .new-progress-bar {
        &.fixed-bar {
            left: 0;
            position: fixed;
            width: 100%;
        }
    }
}

@media screen and (min-width: 992px) {
    .new-progress-bar {
        .progress-bar-nav {
            li {
                .nav-link {
                    &:hover {
                        background-color: $white;
                        border-bottom-color: $red;
                        border-top-color: $red;

                        .first-arrow,
                        &::after {
                            border-color: transparent transparent transparent $red;
                            opacity: 1;
                        }
                    }
                }
            }
        }
    }
}
