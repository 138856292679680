@use '../variables' as *;

/* Generated by LiveSite - Thu May 04 15:51:22 CEST 2017 */
/* For more information, see Appendix D of the user manual. */

body {
    margin: 0;
}

body.ls-center {
    text-align: center;
}

.ls-canvas .ls-row .ls-row-clr {
    clear: both;
}

.ls-canvas .ls-col {
    overflow: hidden;
}

.ls-canvas .ls-col-body {
    overflow: hidden;
}

.ls-canvas .ls-area {
    overflow: hidden;
}

.ls-canvas .ls-area-body {
    overflow: hidden;
}

.ls-canvas .ls-area .ls-1st {
    margin-top: 0 !important;
}

.ls-canvas .ls-cmp-wrap {
    padding: rem(1px) 0;
}

.ls-canvas .iw_component {
    margin: rem(-1px) 0;
}
