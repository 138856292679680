@use '../variables' as *;

/*** GENERIC TEXT ***/

.generic-text {
  font-family: $font-stack-default;

  hr {
    border: 0;
    border-bottom: 1px solid $grey;
  }

  strong {
    font-weight: 700;
  }

  ol {
    padding: 0;

    p {
      display: inline;
    }
  }

  ol {
    counter-reset: lic;
    list-style: none;

    & > li {
      counter-increment: lic;
    }

    & > li::before {
      color: $black;
      content: counter(lic) '.';
      display: inline-block;
      margin-right: 7px;
    }

    li::before {
      position: static;
    }
  }

  a {
    font-weight: bold;
    text-decoration: none;
  }

  blockquote {
    border-left: 5px solid $dark-grey;
    font-style: italic;
    margin-left: 0;
    margin-right: 0;
    overflow: hidden;
    padding-left: 1.5em;
    padding-right: 1.5em;

    p {
      color: $black;
    }
  }

  table {
    tr th,
    tr:first-child td {
      border-bottom: 1px solid $black;
    }

    tr:first-child td {
      font-weight: revert;
    }

    tr th {
      padding: 0 20px 2px 4px;
    }
  }

  .table-bordered thead td,
  .table-bordered thead th {
    line-height: 23px;
  }
}
