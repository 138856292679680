@use '../variables' as *;

@font-face {
    font-family: 'act_fa';
    font-style: normal;
    font-weight: normal;
    src: url('../../fonts/fa/act_fa.eot');
    src: url('../../fonts/fa/act_fa.eot') format('embedded-opentype'),
    url('../../fonts/fa/act_fa.woff2') format('woff2'),
    url('../../fonts/fa/act_fa.woff') format('woff'),
    url('../../fonts/fa/act_fa.ttf') format('truetype'),
    url('../../fonts/fa/act_fa.svg') format('svg');
}

/**
 merge (.accessibility, .follow-us, .social-media-sharing) in one icon class.
 */

.accessibility [class*=' idorsia-icon-']::before,
.follow-us [class*=' idorsia-icon-']::before,
.social-media-sharing [class*=' idorsia-icon-']::before,
.tiles-list [class*=' idorsia-icon-']::before,
.accessibility [class^='idorsia-icon']::before,
.follow-us [class^='idorsia-icon']::before,
.social-media-sharing [class^='idorsia-icon']::before,
.tiles-list [class^='idorsia-icon']::before {
    display: inline-block;
    font-family: 'act_fa';
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-style: normal;
    font-variant: normal;
    font-weight: normal;
    line-height: 1em;
    margin-left: 0.2em;
    margin-right: 0.2em;
    text-align: center;
    text-decoration: inherit;
    text-transform: none;
    width: 1em;
}

.idorsia-icon-x-twitter:before { content: '\e804'; }

.idorsia-icon-mail::before {
    content: '\e800';
}

.idorsia-icon-plus::before {
    content: '\e802';
}

.idorsia-icon-minus::before {
    content: '\e803';
}

.idorsia-icon-adjust::before {
    content: '\e801';
}

.idorsia-icon-print::before {
    content: '\f02f';
}

.idorsia-icon-twitter::before {
    content: '\f099';
}

.idorsia-icon-facebook::before {
    content: '\f09a';
}

.idorsia-icon-mail-alt::before {
    content: '\f0e0';
}

.idorsia-icon-linkedin::before {
    content: '\f0e1';
}

.idorsia-icon-youtube::before {
    content: '\f16a';
}

.accessibility,
.follow-us,
.social-media-sharing {
    .fa-icon {
        background-color: $white;
        border: rem(2px) solid $black;
        border-radius: rem(25px);
        color: $black;
        cursor: pointer;
        display: block;
        float: left;
        font-size: rem(25px);
        height: rem(50px);
        line-height: rem(47px);
        margin-right: rem(15px);
        text-align: center;
        text-decoration: none;
        transition: color 0.3s ease, background-color 0.3s ease,
        border 0.3s ease, -webkit-transform 0.4s ease;
        transition: color 0.3s ease, background-color 0.3s ease,
        transform 0.4s ease, border 0.3s ease;
        transition: color 0.3s ease, background-color 0.3s ease, border 0.3s ease,
        -webkit-transform 0.4s ease;
        transition: color 0.3s ease, background-color 0.3s ease, transform 0.4s ease,
        border 0.3s ease;
        transition: color 0.3s ease, background-color 0.3s ease, transform 0.4s ease,
        border 0.3s ease, -webkit-transform 0.4s ease;
        width: rem(50px);

        &:hover {
            border: rem(3px) solid $red;
            color: $red;
            text-decoration: none;
            transform: rotate(360deg);
            transform: rotate(360deg);
            transform: rotate(360deg);
        }

        &:active {
            background-color: $red;
            color: $white;
        }

        &::after {
            transition: all 0.3s ease;
        }
    }

    .idorsia-icon-deactivated {
        border-color: $grey;
        color: $grey !important;
        cursor: default;

        &:hover {
            border-color: $grey;
            color: $grey;
            transform: none;
            transform: none;
            transform: none;
        }
    }

    .idorsia-icon-facebook {
        &:hover {
            border: rem(3px) solid $facebook;
            color: $facebook;
        }

        &:active {
            background-color: $facebook;
            color: $white;
        }
    }

    .idorsia-icon-twitter {
        &:hover {
            border: rem(3px) solid $twitter;
            color: $twitter;
        }

        &:active {
            background-color: $twitter;
            color: $white;
        }
    }

    .idorsia-icon-x-twitter {
      &:hover {
        border: rem(3px) solid black;
        color: black;
      }

      &:active {
        background-color: black;
        color: $white;
      }
    }

    .idorsia-icon-linkedin {
        &:hover {
            border: rem(3px) solid $linkedin;
            color: $linkedin;
        }

        &:active {
            background-color: $linkedin;
            color: $white;
        }
    }

    .idorsia-icon-youtube {
        &:hover {
            border: rem(3px) solid $youtube;
            color: $youtube;
        }

        &:active {
            background-color: $youtube;
            color: $white;
        }
    }
}
