@use '../variables' as *;

.content-carousel-image {
  padding: rem(15px) 0;
  position: relative;

  .container {
    padding: 0;
    position: relative;
    z-index: 2;

    .content {
      flex-direction: column;

      // Text Carousel
      .text-section {
        .carousel {
          display: flex;
          flex-direction: column;
          height: 100%;
          justify-content: center;
          position: relative;

          h3 {
            font-size: rem(20px);
            margin-top: 0;
          }

          .action-wrapper {
            margin: rem(20px) auto !important;
            position: relative;

            .carousel-indicators {
              left: 0;
              margin: 0 auto;
              position: relative;
              width: 100%;
              z-index: 1;

              li {
                background: $light-grey-200;
                border: none;
                box-shadow: none;
                height: rem(10px);
                margin: 0 rem(6px);
                width: rem(10px);
              }
            }
          }

          .action-wrapper {
            margin: 2rem 0;
          }

          .carousel-control {
            align-items: center;
            display: flex;
            height: 100%;
            z-index: 3;
          }

          .left.carousel-control {
            left: rem(-30px);
          }

          .right.carousel-control {
            right: rem(-30px);
          }
        }
      }

      // Image
      .img-section {
        h2 {
          font-size: rem(20px);
          margin: 0;
          text-align: center;
        }

        .img-wrapper {
          margin: rem(25px) auto;
          max-width: rem(350px);
          text-align: center;
          width: 100%;
        }
      }

      &.carousel-left {
        .img-section {
          order: 2;
        }

        .text-section {
          order: 1;

          .carousel .action-wrapper {
            margin-bottom: rem(30px) !important;
          }
        }
      }
    }
  }
}

@each $theme, $color in $themes {
  .theme-#{$theme} {
    .content-carousel-image .img-section h2 {
      color: $color;
    }

    .content-carousel-image
      .container
      .content
      .text-section
      .carousel
      .carousel-indicators
      .active {
      background: $color;
    }
  }
}

/* ================================
   Responsive
   ================================ */
@media only screen and (max-width: 767px) {
  .content-carousel-image {
    .text-section {
      .carousel {
        .carousel-indicators {
          margin-top: 0;
          position: relative;
          top: 0;
        }

        .left.carousel-control,
        .right.carousel-control {
          bottom: 0;
        }
      }
    }
  }
}

@media screen and (min-width: 768px) {
  .content-carousel-image {
    min-height: rem(360px);

    &.has-background {
      align-items: stretch;
      display: flex;
      min-height: rem(480px);
    }

    .container {
      align-items: center;
      display: flex;

      .content {
        flex-direction: row;
        width: 100%;

        .img-section,
        .text-section {
          flex: 0 0 50%;
          min-height: rem(360px);
          padding: rem(15px);

          .carousel {
            justify-content: flex-start;

            .action-wrapper {
              bottom: rem(-20px);
              left: 50%;
              margin-bottom: 0 !important;
              position: absolute;
              transform: translateX(-50%);
            }
          }

          .img-wrapper {
            margin-bottom: rem(15px);
          }
        }

        &.carousel-left {
          .text-section {
            .carousel {
              .action-wrapper {
                margin-bottom: 0 !important;
              }
            }
          }
        }
      }
    }
  }
}
